/***
 * This components contains routes to change compontent
 * in change of url
 *
 * origin is variable and this is read from context
 * default url is login
 */
import React, { useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "components/pages/Auth/Login";
import SignUp from "components/pages/Auth/SignUp";
import ForgotPassword from "components/pages/Auth/ForgotPassword";
import Message from "components/pages/Auth/Message";
import Home from "components/pages/Home";
import { layoutContext } from "context/layoutContext";
import ChangePassword from "components/pages/Auth/ChangePassword";
import { UserProfile } from "components/pages/Profile/UserProfile";
import HealthPlanIndex from "components/pages/HealthPlan/HealthPlanIndex";
import LabsIndex from "components/pages/Labs/LabsIndex";
import LabRequestPdf from "components/pages/Labs/LabRequestPdf";
import AppointmentsIndex from "../pages/Appointments/AppointmentsIndex";
import CreateAppointment from "../pages/Appointments/CreateAppointment";

const Content = ({ no_background }) => {
  const layoutState = useContext(layoutContext);
  const { origin } = layoutState;

  if (!origin) return null;

  return (
    <div className={`basepage__content ${no_background && "white-background"}`}>
      <Switch>
        <Route
            exact
            path={`/${origin}/my-appointments/create/`}
            component={CreateAppointment}
        />
        <Route
            exact
            path={`/${origin}/my-appointments/`}
            component={AppointmentsIndex}
        />
        <Route
          exact
          path={`/${origin}/my-labs/pdf/`}
          component={LabRequestPdf}
        />
        <Route exact path={`/${origin}/my-labs/`} component={LabsIndex} />
        <Route
          exact
          path={`/${origin}/my-health-plan/`}
          component={HealthPlanIndex}
        />
        <Route
          exact
          path={`/${origin}/change-password/`}
          component={ChangePassword}
        />
        <Route exact path={`/${origin}/login/`} component={Login} />
        <Route exact path={`/${origin}/signup/`} component={SignUp} />
        <Route exact path={`/${origin}/profile/`} component={UserProfile} />
        <Route
          exact
          path={`/${origin}/update-password/`}
          component={() => <Message message="change__password__message" />}
        />
        <Route
          exact
          path={`/${origin}/post-register/`}
          component={() => <Message message="postregister__message" />}
        />
        <Route
          exact
          path={`/${origin}/forgot-password/`}
          component={ForgotPassword}
        />
        <Route
          exact
          path={`/${origin}/change-mail/`}
          component={() => <Message message="change__mail_message" />}
        />
        <Route exact path={`/${origin}/`} component={Home} />
        <Route
          render={() => <Redirect to={{ pathname: `/${origin}/login` }} />}
        />
      </Switch>
    </div>
  );
};

export default Content;
