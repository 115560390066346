import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { useTranslation } from "react-i18next";
import { getDependents } from "data_services/HealthPlan/Dependents";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;

  @media (max-width: 640px) {
    height: 60vh;
  }
`;

const Title = styled.h1``;

const Message = styled.p`
  font-style: italic;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
`;

const Cell = styled(TableCell)`
  background-color: #005487;
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
  width: 16.6666666667%;
`;

const NoDependents = styled.p`
  margin-left: auto;
  margin-right: auto;
  padding: 4rem;
  text-align: center !important;
  font-size: 4rem;
  color: #c3c3c3;

  @media (max-width: 640px) {
    padding: 0;
    width: 100%;
  }
`;

const Row = styled(TableRow)`
  td {
    text-align: center !important;
  }

  &:hover {
    background-color: #d8d9da;
    td {
      font-weight: bold !important;
    }
  }
`;

const Loading = styled(AiOutlineLoading3Quarters)`
  color:#005487;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
`;
const Dependant = () => {
  const { t } = useTranslation();

  const [dependents, setDependents] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const dependets = async () => {
    let result = await getDependents();
    setDependents(result.result.dependents);
    setIsLoading(false);
  };

  useEffect(() => {
    dependets();
  }, []);

  return (
    <Container>
      <Title>{t("my_dependents")}</Title>
      {!isLoading ? (
        <>
          {dependents.length > 0 ? (
            <>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <Cell>
                        <b>{t("my_health_plan__placeholder_Member_Number")}</b>
                      </Cell>
                      <Cell>
                        <b>{t("my_health_plan__placeholder_Name")}</b>
                      </Cell>
                      <Cell>
                        <b>{t("my_health_plan__placeholder_DOB")}</b>
                      </Cell>
                      <Cell>
                        <b>{t("my_health_plan__placeholder_Medical_Plan")}</b>
                      </Cell>
                      <Cell>
                        <b>{t("my_health_plan__placeholder_Vision_Plan")}</b>
                      </Cell>
                      <Cell>
                        <b>{t("my_health_plan__placeholder_Dental_Plan")}</b>
                      </Cell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {dependents.length > 0 &&
                      dependents.map((dependent) => (
                        <Row key={dependent.name}>
                          <TableCell>{dependent.member_id}</TableCell>
                          <TableCell>
                            {dependent.age < 13
                              ? `${dependent.person__first_name}  ${dependent.person__last_name}`
                              : `${t("dependents_message")}`}
                          </TableCell>
                          <TableCell>
                            {dependent.age < 13
                              ? `${dependent.person__date_of_birth}`
                              : "-"}
                          </TableCell>
                          <TableCell>{dependent.plans}</TableCell>
                          <TableCell>{dependent.vision_plan}</TableCell>
                          <TableCell>{dependent.dental_plan}</TableCell>
                        </Row>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Message>
                <b>{t("message_privacity")}</b>
              </Message>
            </>
          ) : (
            <NoDependents>
              {t("my_health_plan_dependents__not_found")}
            </NoDependents>
          )}
        </>
      ) : (
        <>
        <Loading size={100} />
        <Title>{t("loading")}</Title>
        </>
      )}
    </Container>
  );
};

export default Dependant;
