/***
 * Componente para hacer login
 */
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import Error from "components/common/Error";
import excel_logo_login from "assets/images/excel_logo_login.jpg";
import mediexcel_logo_login from "assets/images/mediexcel_logo_login.png";
import { useTranslation } from "react-i18next";
import { FaUserAlt, FaLock } from "react-icons/fa";
import { layoutContext } from "context/layoutContext";
import { authContext } from "context/authContext";
import { LanguageButton } from "components/common/LanguageButon";
import { login } from "data_services/Login";
import { utf8_to_b64 } from "utils/encodes";
import { Link } from "react-router-dom";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5rem;
  

  img:nth-child(1) {
    width: ${({ origin }) => (origin === "mediexcel" ? 12 : 20)}rem;
    /* margin: 1rem 0; */
    margin-top: 1rem !important;

  }

  img:nth-child(2) {
    /* margin-top: 0.5rem; */
    width: 18rem;
  }
`;

export const Form = styled.div`
  width: 25rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  border-top: 0.5rem solid var(--excel-primary-color);
  background-color: var(--excel-third-color);

  h1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    color: black;
  }

  form {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      svg {
        position: absolute;
        margin: 0 -1rem;
        width: 2rem !important;
      }

      :nth-child(3) {
        justify-content: space-between;
      }
    }

    button {
      :disabled {
        cursor: default;
      }

      background-color: var(--excel-primary-color);
      border: none;
      color: white;
      padding: 0.5rem;
      width: 8rem;
      cursor: pointer;
      margin: 0.5rem 0;
    }

    label {
      display: inline-block;
      margin: 0.5rem 0;
    }

    input {
      width: 100%;
      padding: 0.8rem 0.5rem;
      margin: 0.5rem 0;
      border: none;

      :focus {
        border: none;
        outline: none;

        border-bottom: 0.4rem solid var(--excel-primary-color);
        margin-bottom: 0.4rem;
      }
    }

    a {
      color: black;
    }
  }

  @media (max-width: 400px) { 
    width: 20rem;
  }
`;

export const Links = styled.div`
  padding-top: 1rem;
  margin: auto;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.p`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const Text1 = styled.h2`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  
`;


/***
 * Funcion que crea un componente que muestre campos en el formulario
 * @returns: mediante el useState regresa los valores ingresados
 */
const Login = () => {
  const layoutState = useContext(layoutContext);
  const authState = useContext(authContext);
  const { origin } = layoutState;
  const { setToken } = authState;
  const [login_data, setLoginData] = useState({
    username: "",
    password: "",
  });

  const [error, setError] = useState({
    status: false,
    message: null,
  });
  const { t } = useTranslation();
  /***
   * Funcion que interactua con los input para acceder a los valores de "value" cada vez que se actualice
   * @param {object} e accede a los valores ingresados en los input
   */
  const handleChange = (e) => {
    setLoginData({
      ...login_data,
      [e.target.name]: e.target.value,
    });
  };

  /***
   * Funcion que valida cuando se actualiza el login_data
   */
  useEffect(() => {
    validations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [login_data]);

  /***
   * Funcion que valida que el correo sea valido
   */
  const validations = () => {
    setError({
      status: false,
      message: null,
    });

    if (login_data.username && !/\S+@\S+\.\S+/.test(login_data.username)) {
      setError({
        status: true,
        message: t("auth__invalid_email"),
      });
      return;
    }

    if (!login_data.username.length || login_data.password.length < 6) {
      setError({
        status: true,
        message: null,
      });
      return;
    }
  };

  /***
   * Funcion que hace el login una vez esta validado
   */
  const validLogin = (token) => {
    window.localStorage.setItem("token", token);
    window.localStorage.setItem("origin", utf8_to_b64(origin));
    setToken(token);
    window.location.href = `/${origin}/`;
  };

  /***
   * Función cuando el usuario presiona el boton iniciar sesion y los campos estan vacios manda mensaje de error
   */
  const submitLogin = async (e) => {
    e.preventDefault();
    localStorage.removeItem("token");
    setError({
      status: false,
      message: null,
    });

    if (username.trim() === "" || password.trim() === "") {
      setError({
        status: true,
        message: t("login__fields"),
      });
      return;
    }
    let result = await login(login_data);

    if (result.status === "error") {
      setError({
        status: false,
        message: t(result.message),
      });
    } else {
      if (origin === "mediexcel") {
        if (result.result.is_mediexcel) {
          validLogin(result.result.token);
        } else {
          setError({
            status: true,
            message: t("login__is_not_mediexcel"),
          });
        }
      } else {
        if (!result.result.is_mediexcel) {
          validLogin(result.result.token);
        } else {
          setError({
            status: true,
            message: t("login__is_not_excel"),
          });
        }
      }
    }
  };

  const { username, password } = login_data;
  const { status, message } = error;
  return (
    <Content>
      <div origin={origin}>
        <Logo>
          <img
            src={origin === "mediexcel" ? mediexcel_logo_login : excel_logo_login}
            alt="Logo"
          />
        </Logo>
        <Text1><b>{origin === "mediexcel" ? `${t("powered__by_portal")}` : ""}</b></Text1>
        <Text>{origin === "mediexcel" ? `${t("powered__by")}` : ""}</Text>
        <Logo>
          {origin === "mediexcel" && <img src={excel_logo_login} alt="Logo" />}
        </Logo>
      </div>
      <Form origin={origin}>
        <TitleSection>
          <h1>{t("login__title")}</h1>
          <LanguageButton />
        </TitleSection>
        <form onSubmit={submitLogin}>
          <div>
            <input
              onChange={handleChange}
              type="email"
              name="username"
              data-testid="email-input"
              className="u-full-width"
              placeholder={t("login__placeholder_email")}
              value={username}
            />
            <FaUserAlt />
          </div>
          <div>
            <input
              onChange={handleChange}
              type="password"
              name="password"
              data-testid="password-input"
              className="u-full-width"
              placeholder={t("login__placeholder_password")}
              value={password}
            />
            <FaLock />
          </div>
          <div>
            <Link to={`/${origin}/signup/`}>{t("signup__button")}</Link>

            <button
              type="submit"
              data-testid="submit-button"
              className="u-full-width button-primary"
              disabled={status}
            >
              {t("login__button")}
            </button>
          </div>
          <div>
            <Link to={`/${origin}/forgot-password/`}>
              {t("signup_forgot_password")}
            </Link>
          </div>
          <Links>
            {origin === "excel" && (
              <a href="/mediexcel/login/">{t("auth__go_to_mediexcel")}</a>
            )}
            {origin === "mediexcel" && (
              <a href="/excel/login/">{t("auth__go_to_excel")}</a>
            )}
          </Links>
        </form>
        {message && <Error data-testid="error-msg" message={message} />}
      </Form>
    </Content>
  );
};
export default Login;
