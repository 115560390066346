import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { FaPrint } from "react-icons/fa";
import { getLabsRequests } from "data_services/Labs/LabsRequests";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

let origin = window.location.pathname.split("/")[1];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const Title = styled.h1``;

const PdfButton = styled.span`
  border: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  svg {
    font-size: 1.5rem;
    color: black;
  }
`;

const Cell = styled(TableCell)`
  background-color: #262526;
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
  width: 25%;
`;

const Row = styled(TableRow)`
  td {
    text-align: center !important;
  }

  &:hover {
    background-color: #d8d9da;
    td {
      font-weight: bold !important;
    }
  }
`;

const Loading = styled(AiOutlineLoading3Quarters)`
  color: #005487;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const LabsRequests = () => {
  const [requests, setRequests] = useState([]);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const fetchLabsRequests = async () => {
    let result = await getLabsRequests();
    setRequests(result.result);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchLabsRequests();
  }, []);

  const saveLocalStorage = (estado) => {
    localStorage.setItem("request", JSON.stringify(estado));
    window.open(`/${origin}/my-labs/pdf/`, "_blank");
  };

  return (
    <Container>
      <Title>{t("my_labs__requests")}</Title>
      {!isLoading ? (
        <>
          {requests.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <Cell>{t("my_labs__requests_type")}</Cell>
                    <Cell>{t("my_labs__requests_date")}</Cell>
                    <Cell>{t("my_labs__requests_studies")}</Cell>
                    <Cell>{t("my_labs__requests_detail")}</Cell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {requests &&
                    requests.map((request) => (
                      <Row key={request.id}>
                        <TableCell>{t("my_requests")}</TableCell>
                        <TableCell>{request.date_request}</TableCell>
                        <TableCell>{request.list_true.length}</TableCell>
                        <TableCell>
                          <PdfButton onClick={() => saveLocalStorage(request)}>
                            <FaPrint />
                          </PdfButton>
                        </TableCell>
                      </Row>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Title>{t("my_lab__request")}</Title>
          )}
        </>
      ) : (
        <>
          <Loading size={100} />
          <Title>{t("loading")}</Title>
        </>
      )}
    </Container>
  );
};

export default LabsRequests;
