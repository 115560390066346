/***
 * File with pdf container
 */

import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Button = styled.button`
  background-color: #005487;
  padding: 1rem;
  border: none;
  color: white;

  &:hover {
    background-color: #003251;
    cursor: pointer;
  }
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PdfContainer = (props) => {
  /***
   * use ref to specific component to render as pdf
   */
  const { t } = useTranslation();
  const bodyRef = React.createRef();
  const createPdf = () => props.createPdf(bodyRef.current);

  return (
    <section className="pdf-container">
      <Header className="pdf-toolbar">
        <h2>{t("my_health_plan__digital_id")}</h2>
        <Button className="pdf-button" onClick={createPdf}>
          {t("pdf__download_credential")}
        </Button>
      </Header>
      <br />
      <section ref={bodyRef}>{props.children}</section>
    </section>
  );
};

export default PdfContainer;

