/***
 * Content for video list
 */
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { getVideos } from "data_services/HealthPlan/Videos";
import '../../../assets/Videos.css'
import i18n from "i18next";
import ReactPlayer from 'react-player';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { MenuContext } from "context/menuContext";

const Container = styled.div`
  width: 100%;
  display: block;

  @media (max-width: 640px) {
    height: auto;
    overflow: scroll;
    display: ${props => props.showMenu ? 'none' : 'block'};
  }
`;

const ReactPlayerSlide = styled(ReactPlayer)`
  width: 80%;
`;

const VideoContainer = styled.div`
    display: grid;
    flex-direction: column;
    grid-template-columns: 1fr 1fr 1fr;
    width: auto;
    align-self: stretch;
    height: auto;
    overflow: hidden;
`;

const Videos = () => {
  const [videos, setVideos] = useState([]);
  const [selected_video, setSelectedVideo] = useState(0);
  const [error, setError] = useState(false);
  const { showMenu } = useContext(MenuContext);

  useEffect(() => {
    fetchVideos();
  }, []);


   const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;
   const getVideoThumb = (videoId) => videoId

   const YoutubeSlide = ({ url, isSelected }: { url: string; isSelected?: boolean }) => (
      <ReactPlayerSlide width={"85%"} controls={true} url={url} playing={false} />  
    );

   const customRenderThumb = () =>
            videos.map((item) => {
                return <VideoContainer><img src={getVideoThumb(item.thumb)} /><span>{item.title}</span></VideoContainer>;
            });


  /***
   * Method to fetch all videos from server
   */
  const fetchVideos = async () => {
    let result = await getVideos()
    try{
      result = result.result.filter((item) => {
        if (i18n.language === "en") return !item.spanish;
        else return item.spanish;
      });
      setVideos(result);
    }catch{
      setError(result.error);
    }
  };

  useEffect(() => {
    fetchVideos();
  }, [i18n.language]);

  /***
   * if there are no videos, don't render the component
   */
  if (videos.length === 0) {
    return null;
  }
  return (
    <Container showMenu={showMenu} selected_video={selected_video}>
      <div>
        <Carousel
          renderItem={customRenderItem}
          renderThumbs={customRenderThumb}
          showStatus={false}
          renderIndicator={false}
          thumbWidth={'200px'}
        >
          {videos?.map(video => (
            <YoutubeSlide key={video.id} url={video.url} thumb={video.thumb} />
          ))}
        </Carousel>
      </div>
    </Container>
  );
};
export default Videos;
