import React from "react";
import i18n from "i18next";
import styled from "styled-components";

const List = styled.li`
  list-style-type: none;
  margin-top: 10px;
`;
const ListElement = styled.ul``;
const Card = styled.div`
  width: 300px;
  height: 210px;
  display: flex;
  border-bottom: 1px solid #ccc;
  flex-direction: column;

  &:hover {
    box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);
  }
`;

const CardImage = styled.div`
  height: 40%;
  display: flex;
  justify-content: center;
`;

const Logo = styled.img`
  object-fit: contain;
`;

const CardContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;
`;
const CardTitle = styled.p`
  font-size: 1rem;
  font-weight: bold;
  color: #005487;
  margin-bottom: 10px;
  word-wrap: break-word;
`;

const CardText = styled.p`
  font-size: .75rem;
  margin-bottom: 5px;
  word-wrap: break-word;
`;

const CardPhone = styled.p`
  font-size: .75rem;
  margin-bottom: 5px;
  width: 40%;
  word-wrap: break-word;
`;

const CardLink = styled.a`
  font-size: .75rem;
  text-decoration: underline;
  color: black;
`;

const Provider = ({ providers }) => {
  if (!providers) return null;
  return (
    <>
      {providers.length &&
        providers.map((provider) => (
          <>
          <List>
            <ListElement>
              <Card>
                <CardImage>
                  <Logo src={provider.photo} alt=""  />
                </CardImage>
                <CardContent>
                  <CardTitle dangerouslySetInnerHTML={{__html: 
                    i18n.language === "en"
                    ? provider.working_hours
                    : provider.working_hours_spanish}}>
                    
                  </CardTitle>
                  <CardText dangerouslySetInnerHTML={{__html: 
                    i18n.language === "en"
                    ? provider.address
                    : provider.address_spanish }}>
                    </CardText>
                  {provider.phone !== "" ? (
                    <CardPhone>{provider.phone}</CardPhone>
                  ) : (
                    ""
                  )}
                  {provider.web_page !== "" ? (
                    <CardLink href={provider.web_page} target="_blank">
                      {provider.web_page}
                    </CardLink>
                  ) : (
                    ""
                  )}
                </CardContent>
              </Card>
            </ListElement>
          </List>
          </>
        ))}
    </>
  );
};

export default Provider;