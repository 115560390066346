import React, { useState, useEffect, createContext } from 'react';
import { Menu } from 'components/layout/Menu';

export const MenuContext = createContext();
export const MenuProvider = ({ children }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showMenuProfile, setShowMenuProfile] = useState(false);
  const [showVisible, setVisible] = useState(true);
  const [active_component, setActiveComponent] = useState("my_health_plan__info");

/**
 * Handles the change event for setting the active component.
 *
 * @param {Object} e - The event object.
 * @param {string} e.target.name - The name of the target component.
 */

/**
 * Sets the active component based on the clicked button's name.
 * @param {Object} e - The event object triggering the function.
 */
  const handleChange = (e) => {
    setActiveComponent(e.target.name);
  };

/**
 * Handles click on the icon to toggle the menu visibility.
 */
  const handleIconClick = () => {
    setShowMenu(!showMenu); 
  };

/**
 * Toggles the visibility of the video between visible and hidden.
 */
  const toggleVideo = () => {
    setVisible(prevShowVideo => !prevShowVideo);
  };

  let menu;

  if(showMenu) {
    menu = <Menu showMenu={showMenu} setShowMenu={setShowMenu} setActiveComponent={setActiveComponent} />
  }

  return (
    <MenuContext.Provider value={{
      handleIconClick,
      handleChange,
      active_component,
      setActiveComponent,
      setShowMenu,
      showMenu,
      showMenuProfile,
      setShowMenuProfile,
      toggleVideo,
      showVisible,

      menu
    }}>
      {children}
    </MenuContext.Provider>
  )
}