/***
 * Componente para registrar
 */
import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import InputMask from "react-input-mask";
import Error from "components/common/Error";
import excel_logo from "assets/images/excel_logo.png";
import mediexcel_logo from "assets/images/mediexcel_logo.png";
import { useTranslation } from "react-i18next";
import { layoutContext } from "context/layoutContext";
import {
  FaUserAlt,
  FaLock,
  FaCreditCard,
  FaCalendar,
  FaAt,
} from "react-icons/fa";
import { LanguageButton } from "components/common/LanguageButon";
import { signup } from "data_services/SignUp";
import { Link } from "react-router-dom";
import i18n from "i18next";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  img:nth-child(1) {
    width: ${({ origin }) => (origin === "mediexcel" ? 12 : 18)}rem;
    margin-top: 1rem !important;
  }

  img:nth-child(2) {
    /* margin-top: 1rem; */
    width: 18rem;
  }
`;

export const Form = styled.div`
  width: 50rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  border-top: 0.5rem solid var(--excel-primary-color);
  background-color: var(--excel-third-color);

  h1 {
    display: flex;
    justify-content: space-between;
    margin: 0.4rem 0;
    color: black;
  }

  form {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      svg {
        position: absolute;
        margin: 0 -1rem;
      }

      :nth-last-child(2) {
        justify-content: space-between;
      }
    }

    button {
      :disabled {
        cursor: default;
      }

      background-color: var(--excel-primary-color);
      border: none;
      color: white;
      padding: 0.5rem;
      width: 8rem;
      cursor: pointer;
      margin: 0.5rem 0;
    }

    label {
      display: inline-block;
      margin: 0.5rem 0;
    }

    input,
    select {
      width: 100%;
      padding: 0.8rem 0.5rem;
      margin: 0.5rem 0;
      border: none;

      :focus {
        border: none;
        outline: none;
        border-bottom: 0.4rem solid var(--excel-primary-color);
      }
    }

    select {
      background-color: white;
    }

    a {
      color: black;
    }
  }
`;
const AgreementText = styled.p`
  font-size: 0.85rem;
  margin-top: 0.5rem;
  color: gray;
  text-align: justify;
`;

export const InputMember = styled.div`
  display: flex;
  background: #fff;
  height: 2.8rem;
  width: 100%;
  padding-left: 0.5rem;
  overflow: hidden;

  span {
    font-weight: bold;
    color: #7e8084;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 1 auto;
  width: 100%;
`;

const Col4 = styled.div`
  flex: 0 0 auto;
  width: 33.33%;
  padding: 0 5px 0 5px;
`;

const Col6 = styled.div`
  flex: 0 0 auto;
  width: 50%;
  padding: 0 5px 0 5px;
`;

const DIV = styled.div`
  width: 100%;
`;

const Text = styled.p`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const TitleSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 5px 0 5px;
`;
const SignUp = () => {
  const layoutState = useContext(layoutContext);
  const { origin } = layoutState;

  /***
   * Funcion que crea un componente que muestre campos en el formulario
   * @returns con la variable "resgister" de useState regresamos los valores ingresados
   */
  const [register_data, setRegister] = useState({
    first_name: "",
    last_name: "",
    date_of_birth: "",
    member_number: "",
    credential_number: "",
    email: "",
    password: "",
    confirm_password: "",
    is_mediexcel: origin === "mediexcel",
    language: "",
  });

  const [inputType, setInputType] = useState("text");

  /***
   * Componente de Error, se inicializa como false
   */
  const [error, setError] = useState({
    status: false,
    message: null,
  });
  const { t } = useTranslation();

  /***
   * Funcion que interactua con los inputs para acceder a los valores de "value" cada vez que se actulice
   * @param {object} e accede a los valores del input
   */
  const handleChange = (e) => {
    setRegister({
      ...register_data,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
      language: i18n.language,
    });
  };

  /***
   * Funcion que cambia el placeholder
   */
  const changeType = () => {
    if (inputType === "text") {
      setInputType("date");
    } else {
      setInputType("text");
    }
  };

  /***
   * Funcion que valida cuando se actualiza el registro
   */
  useEffect(() => {
    validations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [register_data]);

  /***
   * Funcion que valida que los campos de nombre y correo no esten vacios y si lo estan envia mensaje
   */
  const validations = () => {
    setError({
      status: false,
      message: null,
    });

    if (
      register_data.password.length > 0 &&
      register_data.password.length < 6
    ) {
      setError({
        status: true,
        message: t("signup__password_short"),
      });
      return;
    }

    if (register_data.password !== register_data.confirm_password) {
      setError({
        status: true,
        message: t("signup__password_match"),
      });
      return;
    }

    if (register_data.email && !/\S+@\S+\.\S+/.test(register_data.email)) {
      setError({
        status: true,
        message: t("auth__invalid_email"),
      });
      return;
    }

    if (
      !register_data.first_name ||
      !register_data.last_name ||
      !register_data.email ||
      !register_data.password
    ) {
      setError({
        status: true,
        message: null,
      });
      return;
    }
  };

  /***
   *
   */
  const submitLogin = async (e) => {
    e.preventDefault();
    let result = await signup(register_data);
    if (result.status === "error") {
      setError({
        status: true,
        message: t(result.message),
      });
    } else {
      window.location.href = `/${origin}/post-register/`;
    }
  };

  const {
    first_name,
    last_name,
    date_of_birth,
    member_number,
    credential_number,
    email,
    password,
    confirm_password,
    is_mediexcel,
  } = register_data;

  const { message, status } = error;

  const formatChars = {
    P: "[ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz]",
    0: "[0123456789]",
  };

  return (
    <Content>
      <div origin={origin}>
        <Logos>
          <img
            src={origin === "mediexcel" ? mediexcel_logo : excel_logo}
            alt="Logo"
          />
        </Logos>
        <Text>
          <h2><b>{origin === "mediexcel" ? `${t("powered__by_portal")}` : ""}</b></h2>
        </Text>
        <Text>{origin === "mediexcel" ? `${t("powered__by")}` : ""}</Text>
        <Logos>
          {origin === "mediexcel" && <img src={excel_logo} alt="Logo" />}
        </Logos>
      </div>
      <Form origin={origin}>
        <TitleSection>
          <h1>{t("signup__title")}</h1>
          <LanguageButton />
        </TitleSection>
        <form onSubmit={submitLogin}>
          <Row>
            <Col6>
              <DIV>
                <FaUserAlt />
                <input
                  onChange={handleChange}
                  type="text"
                  name="first_name"
                  className="u-full-width"
                  placeholder={t("signup__placeholder_first_name")}
                  value={first_name}
                  data-testid="first-name-input"
                  required
                />
              </DIV>
            </Col6>
            <Col6>
              <DIV>
                <FaUserAlt />
                <input
                  onChange={handleChange}
                  type="text"
                  name="last_name"
                  className="u-full-width"
                  placeholder={t("signup__placeholder_last_name")}
                  value={last_name}
                  data-testid="last-name-input"
                  required
                />
              </DIV>
            </Col6>
          </Row>
          <Row>
            <Col6>
              <DIV>
                <FaCalendar />
                <input
                  onChange={handleChange}
                  type={inputType}
                  name="date_of_birth"
                  className="u-full-width"
                  placeholder={t("signup__placeholder_date_of_birth")}
                  value={date_of_birth}
                  data-testid="date-birth-input"
                  onFocus={changeType}
                  onBlur={changeType}
                />
              </DIV>
            </Col6>
            <Col6>
              {!is_mediexcel && (
                <InputMember>
                  <FaCreditCard />
                  <span className="prefix">Excel-</span>
                  <input
                    onChange={handleChange}
                    type="text"
                    name="member_number"
                    className="u-full-width"
                    placeholder={t("signup__placeholder_member")}
                    value={member_number}
                    data-testid="member-number-input"
                  />
                </InputMember>
              )}
              {is_mediexcel && (
                <DIV>
                  <FaCreditCard />
                  <InputMask
                    mask="P0000000-00"
                    name="credential_number"
                    placeholder={t("signup__placeholder_member")}
                    formatChars={formatChars}
                    value={credential_number}
                    onChange={handleChange}
                  ></InputMask>
                </DIV>
              )}
            </Col6>
          </Row>
          <Row>
            <Col4>
              <DIV>
                <FaAt />
                <input
                  onChange={handleChange}
                  type="email"
                  name="email"
                  className="u-full-width"
                  placeholder={t("login__placeholder_email")}
                  value={email}
                  data-testid="email-input"
                  required
                />
              </DIV>
            </Col4>
            <Col4>
              <DIV>
                <FaLock />
                <input
                  onChange={handleChange}
                  type="password"
                  name="password"
                  className="u-full-width"
                  placeholder={t("login__placeholder_password")}
                  data-testid="password-input"
                  value={password}
                  required
                />
              </DIV>
            </Col4>
            <Col4>
              <DIV>
                <FaLock />
                <input
                  onChange={handleChange}
                  type="password"
                  name="confirm_password"
                  className="u-full-width"
                  placeholder={t("signup__placeholder_confirm_password")}
                  data-testid="confirm-password-input"
                  value={confirm_password}
                />
              </DIV>
            </Col4>
          </Row>
          <Row>
            <Col6>
              <Link to={`/${origin}/login/`}>{t("login__button")}</Link>
            </Col6>
            <Col6>
              <button
                type="submit"
                className="u-full-width button-primary"
                disabled={status}
                data-testid="submit-button"
              >
                {t("signup__button")}
              </button>
            </Col6>
          </Row>
          <Row>
            <DIV>
              <AgreementText origin={origin}>
                {t("signup__agreement_text")}
              </AgreementText>
            </DIV>
          </Row>
        </form>
        {status && <Error message={message} />}
      </Form>
    </Content>
  );
};
export default SignUp;
