/***
 * File to specified pdf details
 */

import { savePDF } from "@progress/kendo-react-pdf";

class DocService {
  createPdf = (html) => {
    savePDF(html, {
      paperSize: ["54mm", "86mm"],
      fileName: "credential.pdf",
      margin: "0",
      landscape: true,
      scale: 0.25,
      repeatHeaders: true,
      author: "Mediexcel",
      title: "My Credential",
    });
  };
}

const Doc = new DocService();
export default Doc;
