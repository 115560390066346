/***
 * Componente para cambiar contraseña
 */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import excel_logo from "assets/images/excel_logo.png";
import mediexcel_logo from "assets/images/mediexcel_logo.png";
import { FaLock } from "react-icons/fa";
import Error from "components/common/Error";
import axiosClient from "config/axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import i18n from "i18next";

let origin = window.location.pathname.split("/")[1];

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  img:nth-child(1) {
    width: ${({ origin }) => (origin === "mediexcel" ? 15 : 25)}rem;
    margin: 1rem 0;
  }

  img:nth-child(2) {
    width: 25rem;
  }
`;

export const Form = styled.div`
  width: 25rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  border-top: 0.5rem solid var(--excel-primary-color);
  background-color: var(--excel-third-color);

  h1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    color: black;
  }

  form {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      svg {
        position: absolute;
        margin: 0 -1rem;
        width: 2rem !important;
      }

      :nth-child(3) {
        justify-content: space-between;
      }
    }

    button {
      :disabled {
        cursor: default;
      }

      background-color: var(--excel-primary-color);
      border: none;
      color: white;
      padding: 0.5rem;
      width: 8rem;
      cursor: pointer;
      margin: 0.5rem auto;
    }

    label {
      display: inline-block;
      margin: 0.5rem 0;
    }

    input {
      width: 100%;
      padding: 0.8rem 0.5rem;
      margin: 0.5rem 0;
      border: none;

      :focus {
        border: none;
        outline: none;

        border-bottom: 0.4rem solid var(--excel-primary-color);
        margin-bottom: 0.4rem;
      }
    }

    a {
      color: black;
    }
  }
`;

const ChangePassword = () => {
  const { t } = useTranslation();

  const [data, setData] = useState({
    password: "",
    confirm_password: "",
    language: "",
  });
  const [error, setError] = useState({
    status: false,
    message: null,
  });
  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
      "language": i18n.language
    });
  };

  useEffect(() => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const language = urlParams.get("language");
    i18n.changeLanguage(language);
  }, []);
  /***
   * Funcion que valida cuando se actualiza el registro
   */
  useEffect(() => {
    validations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);
  /***
   * Funcion que valida que los campos cumplan con los requerimientos y si no envia mensaje
   */

  const validations = () => {
    setError({
      status: false,
      message: null,
    });

    if (data.password.length > 0 && data.password.length < 6) {
      setError({
        status: true,
        message: t("signup__password_short"),
      });
      return;
    }

    if (data.password !== data.confirm_password) {
      setError({
        status: true,
        message: t("signup__password_match"),
      });
      return;
    }
    if (!data.password || !data.confirm_password) {
      setError({
        status: true,
        message: null,
      });
      return;
    }
  };

  /***
   *
   */
  const submitChange = async (e) => {
    e.preventDefault();

    setError({
      status: false,
      message: null,
    });

    if (password.trim() === "" || confirm_password.trim() === "") {
      setError({
        status: true,
        message: t("login__fields"),
      });
      return;
    } else {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const token = urlParams.get("token");
      let data = {
        token: token,
        password: password,
        language: i18n.language,
      };
      await axiosClient
        .post("/auth/reset_password/", data)
        .then((response) => {
          if (response.status === 202) {
            toast.success(t("change_password_confirmed"));
            setTimeout(() => {
              window.location.href = `/${origin}/login/`;
            }, 3000);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const { password, confirm_password } = data;
  const { message, status } = error;
  return (
    <Content>
      <Logos origin={origin}>
        <img
          src={origin === "mediexcel" ? mediexcel_logo : excel_logo}
          alt="Logo"
        />
        {origin === "mediexcel" && <img src={excel_logo} alt="Logo" />}
      </Logos>
      <ToastContainer />
      <Form origin={origin}>
        <h1>{t("change_password")}</h1>
        <form onSubmit={submitChange}>
          <div>
            <input
              onChange={handleChange}
              type="password"
              name="password"
              data-testid="password-input"
              className="u-full-width"
              placeholder={t("change_password__placeholder_password")}
              value={password}
            />
            <FaLock />
          </div>
          <div>
            <input
              onChange={handleChange}
              type="password"
              name="confirm_password"
              data-testid="confirm-password-input"
              className="u-full-width"
              placeholder={t("change_password__placeholder_confirm_password")}
              value={confirm_password}
            />
            <FaLock />
          </div>
          <div>
            <button
              type="submit"
              data-testid="submit-button"
              className="u-full-width button-primary"
              disabled={status}
            >
              {t("change_password__button")}
            </button>
          </div>
        </form>
        {status && <Error message={message} />}
      </Form>
    </Content>
  );
};

export default ChangePassword;
