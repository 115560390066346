import React from "react";
import styled from "styled-components";
import i18n from "i18next";

const List = styled.li`
  width: 50%;
  list-style-type: none;
  margin-top: 10px;

  @media (max-width: 768px) {
    width: 100%;
  }
`;
const ListElement = styled.ul``;

const Card = styled.div`
  width: auto;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 0 5px 0 5px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.16);
  }
`;

const CardTitle = styled.p`
  font-size: 1rem;
`;

const CardText = styled.p`
  color: #b0b3b8;
  font-size: 1rem;
`;

const Copays = ({ source }) => {
  if (!source) return null;
  return (
    <>
      {source.length &&
        source.map((copay, index) => (
          <List key={index}>
            <ListElement>
              <Card>
                <CardTitle>
                  {i18n.language === "en"
                    ? copay.service_type.description_eng
                    : copay.service_type.description}
                </CardTitle>
                {copay.percentage ? (
                  <CardText>
                    {copay.copayment}%
                    {copay.limit ? `(max $${copay.limit})` : ""}
                  </CardText>
                ) : (
                  <CardText>${copay.copayment}</CardText>
                )}
              </Card>
            </ListElement>
          </List>
        ))}
    </>
  );
};

export default Copays;
