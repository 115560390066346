import React, { createContext, useReducer } from "react";
import axiosClient from "config/axios";
import tokenAuth from "config/tokenAuth";

let path_origin = window.location.pathname.split("/")[1];

/***
 * Creando el context
 */
export const authContext = createContext();
/***
 * Creando el useReducer utilizando un switch para el primer caso si es SET_LOGOUT trae los datos del usuarion en caso
 * de ser LOGOUT limpia la sesion.
 */
const authReducer = (state, action) => {
  switch (action.type) {
    case "SET_TOKEN":
      return {
        ...state,
        token: action.payload.token,
      };
    case "SET_USERDATA":
      return {
        ...state,
        user: action.payload,
      };
    case "SET_LOGOUT":
      return {
        ...state,
        user: {
          is_mediexcel: true,
          preferred_language: 1,
          date_of_birth: null,
          id: null,
          first_name: null,
          last_name: null,
          email: null,
          username: null,
          is_active: false,
        },
        message: null,
      };
    default:
      return state;
  }
};

/***
 * Creando un Provider es donde van a salir los datos y las funciones es la referencia al context
 */
const AuthProvider = (props) => {
  /***
   * Creando el state del context declarando las variables
   */
  const initialState = {
    token: "",
    user: {
      id: null,
      name: "",
      permissions: "",
    },
    is_mediexcel: false,
    preferred_language: 1,
    message: null,
  };
  /***
   *Actualiza el state por medio de la función dispatch
   */
  const [state, dispatch] = useReducer(authReducer, initialState);

  /***
   * Función que cambia los datos
   * @param (data)
   */
  const setToken = (data) => {
    dispatch({
      type: "SET_TOKEN",
      payload: data,
    });
  };

  const setUserData = (data) => {
    dispatch({
      type: "SET_USERDATA",
      payload: data,
    });
    tokenAuth(data.token);
  };

  /***
   * Función que limpia la sesion
   */
  const setLogout = async () => {
    let token = localStorage.getItem("token");
    localStorage.removeItem("token");
    localStorage.removeItem("origin");
    if (token) {
      await axiosClient
        .post("/auth/logout/", { token: token })
        .then((response) => {
          dispatch({
            type: "SET_LOGOUT",
            payload: "",
          });
          window.location.href = `/${path_origin ? path_origin : 'mediexcel'}/login/`;
        });
    }
  };

  return (
    <authContext.Provider
      value={{
        user: state.user,
        setToken,
        setLogout,
        setUserData,
      }}
    >
      {props.children}
    </authContext.Provider>
  );
};
export default AuthProvider;
