import React, { useEffect, useState } from "react";
import styled from "styled-components";
import generateBarcode from "pdf417";
import id_member_front from "assets/images/id_member_front.jpg";
import id_member_back from "assets/images/id_member_back.jpg";
import axiosClient from "config/axios";
import Doc from "utils/DocService";
import PdfContainer from "utils/PdfContainer";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { useTranslation } from "react-i18next";

/***
 * Estilos de la credencial y textos que van sobre la imagen
 */

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

const EXP = styled.div`

  img {
    width: 99%;
  }

  p {
    font-family: "Open Sans", sans-serif;
    font-size: 1.8em;
    color: #005487;
    font-weight: bold;
    font-style: normal;
  }

  span {
    color: #005487;
    font-size: 1.4em;
    font-style: italic;
    font-weight: bold;
  }
`;

const EXPBACK = styled.div`

  img {
    width: 99%;
  }
`;

const Loading = styled(AiOutlineLoading3Quarters)`
  color: #005487;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const Title = styled.h1``;

/***
 * Funcion que accede a la informacion de la credencial mediante la API
 * @returns {JSX.Element}Retorna result.data
 */
const Credential = () => {
  /***
   * Estado que muestra la informacion de la credencial
   */
  const [credential, setCredential] = useState({
    member: {
      full_name: "",
      member_id: "",
      date_of_birth: "",
    },
    opm_data: {
      name: "",
      deductible: "",
      ind_fam: "",
    },
    effective_date: "",
  });

  const [isLoading, setIsLoading] = useState(true);

  const { t } = useTranslation();

  /***
   * Metodo que trae información de la API y guarda la informacion en la variable "result"
   */
  const renderBarcode = (credencial) => {
    let code = `BGN*V1*MEHP*CARD~
DTP*D8*${credencial.today}~
INS*Y*${credencial.patient_id}*PER*${credencial.person_id}~
NM1*1L*${credencial.member.last_name}*${credencial.member.first_name}*${credencial.member.middle_name}*${credencial.member.mothers_maiden_name}~
DMG*D8*${credencial.member.date_credential}*U~
REF*1L*${credencial.member.member_id}~
REF*ZZ*EXCEL-${credencial.patient_id}~`;

    let canvas = document.getElementById("barcode");
    if (canvas !== null) {
      /*canvas.setAttribute("src", generateBarcode(code, 16, 2.4));*/
      /* let img = canvas.toDataURL("image/png");
            let back = document.getElementById('back');
            back.innerHTML += `<img src="${img}"></img>`; */
    }
  };

  const fetchDigital = async () => {
    let result = await axiosClient.get("/user_management/digital_id/");
    setCredential(result.data);
    renderBarcode(result.data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchDigital();
  }, []);

  const createPdf = (html) => Doc.createPdf(html);

  return (
    <PdfContainer createPdf={createPdf}>
      <Container>
        <EXP>
          <img src={id_member_front} alt="front" />

          <div>
            <div style={{ top: "3rem" }}>
              {!isLoading ? (
                <vr>
                  <p>{credential.member.full_name}</p>
                  <span> Member ID {credential.member.member_id} </span>
                  <br />
                  <br />
                  <span>DOB: {credential.member.date_of_birth}</span>
                  <br />
                  <br />
                  {credential.opm_data&&
                    <>
                      <p>{credential.opm_data.name} Plan</p>
                      <span>{credential.opm_data.deductible} Deductible </span><br />
                      <span>{credential.opm_data.ind_fam} OOPM</span>
                      <br />
                      <br />
                    </>
                  }
                  {credential.opm_data_dental&&
                    <>
                      <p>{credential.opm_data_dental.name} Plan</p>
                    </>
                  }
                  {credential.opm_data_vision&&
                    <>
                      <p>{credential.opm_data_vision.name} Plan</p>
                    </>
                  }
                  <br />
                  <p>{credential.effective_date}</p>
                  <span> Effective Date </span>
                </vr>
              ) : (
                <>
                  <Loading size={100} />
                  <Title>{t("loading")}</Title>
                </>
              )}
            </div>
          </div>
        </EXP>
        <EXPBACK>
          <img src={id_member_back} alt="back" />
          <div>
            <div>
              
            </div>
          </div>
        </EXPBACK>
      </Container>
    </PdfContainer>
  );
};
export default Credential;
