/***
 * Context to save the variables related to the layout
 */

import React, { createContext, useReducer } from "react";

/***
 * Start layoutContext from createContext
 */
export const layoutContext = createContext();

/***
 * Layout reducer to change state
 * @param {object} state state with context data.
 * @param {object} action dict with action type and payload
 * @param {string} action.type use case in reducer for change state
 * @param {object} action.payload dict with action type and payload
 * @returns a copy of state updated
 */
const layoutReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_ORIGIN":
      return {
        ...state,
        origin: action.payload,
      };
    default:
      return state;
  }
};

/***
 *
 */
const LayoutProvider = (props) => {
  /***
   * initialState contains all vars and their default values
   */
  const initialState = {
    origin: null,
  };

  /***
   * useReducer to get state and dispatch
   */
  const [state, dispatch] = useReducer(layoutReducer, initialState);

  /***
   * update origin value from state using dispatch
   */
  const setOrigin = (data) => {
    dispatch({
      type: "CHANGE_ORIGIN",
      payload: data,
    });
  };

  /***
   * Provider cover the components to share variables
   * and methods with them
   */
  return (
    <layoutContext.Provider
      value={{
        origin: state.origin,
        setOrigin,
      }}
    >
      {props.children}
    </layoutContext.Provider>
  );
};

export default LayoutProvider;
