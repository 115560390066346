import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Content = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4rem;

  p:nth-child(1) {
    font-size: 20rem;
    color: var(${({ origin }) => `--${origin}-third-color`});
  }

  p:nth-child(2) {
    font-size: 4rem;
    color: var(${({ origin }) => `--${origin}-secondary-color`});
  }

  p:nth-child(3) {
    font-size: 2rem;
  }
`;

const Button = styled.button`
  background-color: var(${({ origin }) => `--${origin}-third-color`});
  padding: 1rem;
  border: none;
  color: white;

  &:hover {
    background-color: #003251;
    cursor: pointer;
  }
`;

const NotFound = ({ origin }) => {
  const { t } = useTranslation();

  return (
    <Content origin={origin}>
      <p>404</p>
      <p>{t("not_found__title")}</p>
      <p>{t("not_found__content")}</p>
      <a href={`/${origin}/`}>
        <Button origin={origin}>{t("message__go_to_origin")}</Button>
      </a>
    </Content>
  );
};

export default NotFound;
