import axiosClient from "../config/axios";
import i18n from "i18next";

export const requestInfoUpate = async (request_body) => {
  let result = {};
  request_body = {
    ...request_body,
    "language":i18n.language,
  }
  await axiosClient
    .post("/contacts/update_info_requests/", request_body)
    .then((response) => {
      result = {
        status: "ok",
        result: response.data,
      };
    })
    .catch((error) => {
      try {
        result = {
          status: "error",
          message: error.response.data.result.message,
        };
      } catch (e) {
        result = {
          status: "error",
          message: "auth__server_error",
        };
      }
    });
  return result;
};
export const getRequestInfoList = async () => {
  let result = {};
  await axiosClient
    .get("/contacts/update_info_requests/")
    .then((response) => {
      result = {
        status: "ok",
        result: response.data.results,
      };
    })
    .catch((error) => {
      try {
        result = {
          status: "error",
          message: error.response.data.result.message,
        };
      } catch (e) {
        result = {
          status: "error",
          message: "auth__server_error",
        };
      }
    });
  return result;
};
