/***
 * Component to change mail
 */
import React, { useState, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FaAt } from "react-icons/fa";
import Error from "components/common/Error";
import axiosClient from "config/axios";
import { authContext } from "context/authContext";
import i18n from "i18next";

let origin = window.location.pathname.split("/")[1];

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Form = styled.div`
  width: 25rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;

  h1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
  }

  form {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      svg {
        position: absolute;
        margin: 0 -1rem;
        width: 2rem !important;
      }

      :nth-child(3) {
        justify-content: space-between;
      }
    }

    button {
      :disabled {
        cursor: default;
        background-color: #7e8084;
      }

      background-color: #262526;
      border: none;
      color: white;
      padding: 0.5rem;
      width: 8rem;
      cursor: pointer;
      margin: 0.5rem 0;
    }

    input {
      width: 100%;
      padding: 0.8rem 0.5rem;
      margin: 0.5rem 0;

      :focus {
        outline: none;

        border: 0.2rem solid var(--excel-primary-color);
        margin: 0.2rem;
      }
    }
  }
`;

export const UserChangeMailData = () => {
  const authState = useContext(authContext);
  const { user } = authState;
  const { t } = useTranslation();

  /***
   * Funcion que crea un componente que muestre campos del formulario
   */
  const [change_mail, setChangeMail] = useState({
    email_change: "",
  });

  /***
   * Error component is initialized as false
   */
  const [error, setError] = useState({
    status: false,
    message: null,
  });

  /***
   * funcion que interactua con los inputs para acceder a los valores value
   * @param {object} e accede al valor del input
   */
  const handleChange = (e) => {
    setError({
      status: false,
      message: null,
    })
    setChangeMail({
      ...change_mail,
      [e.target.name]: e.target.value,
    });
  };

  /***
   * peticion para comprobar si el correo ya existe
   */
  const UpdateMail = async (data) => {
    let result = {};
    let _data = {
      email: change_mail.email_change,
      user: user.id,
      language: i18n.language,
    };
    await axiosClient
      .post("/auth/request_token_email/", _data)
      .then((response) => {
        result = {
          status: "ok",
          result: response.data.result,
        };
        window.location.href = `/${origin}/change-mail/`;
      })
      .catch((error) => {
        try {
          result = {
            status: "error",
            message: t(error.response.data.result.message),
          };
        } catch (error) {
          result = {
            status: "error",
            message: t("changemail__email_exists"),
          };
        }
      });
    return result;
  };

  const submitChangeMail = async (e) => {
    e.preventDefault();
    let result = await UpdateMail(change_mail);
    if (result.status === "error") {
      setError({
        status: true,
        message: t(result.message),
      });
    }
  };

  // validar que el tipo de email ingresado sea correcto
  const validateEmail = (email) => {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };





  const { email_change } = change_mail;

  const { message, status } = error;

  return (
    <Container>
      <Form origin={origin} onSubmit={submitChangeMail}>
        <h1>{t("change_email")}</h1>
        <form>
          <div>
            <input
              onChange={handleChange}
              type="email"
              name="email_change"
              data-testid="email-change-input"
              className="u-full-width"
              placeholder={t("login__placeholder_email")}
              value={email_change}
              required
            />
            <FaAt />
          </div>
          <div>
            <button
              type="submit"
              data-testid="submit-button"
              className="u-full-width button-primary"
              disabled={validateEmail(email_change) ? false : true}
            >
              {t("change_password__button")}
            </button>
          </div>
        </form>
      </Form>
      {status && <Error message={message} />}
    </Container>
  );
};
