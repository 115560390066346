import axiosClient from "config/axios";
/***
 * get coverages from backend
 */
export const getCoverages = async (data) => {
  let result = {};
  await axiosClient
    .get("/coverages/my_plan/", data)
    .then((response) => {
      result = {
        error: false,
        result: response.data,
      };
    })
    .catch((error) => {
      result = {
        error: true,
        message: "auth__server_error",
      };
    });
  return result;
};
