import axiosClient from "config/axios";
import i18n from "i18next";

/***
 * get coverages from backend
 */
export const sendAppointments = async (data) => {
    let result = {};
    data = {
        ...data,
        "language":i18n.language, 
    }
    await axiosClient
        .post("/schedule/send_appointments/", data)
        .then((response) => {
            result = {
                error: false,
                result: response.data,
            };
        })
        .catch((error) => {
            result = {
                error: true,
                message: "auth__server_error",
            };
        });
    return result;
};
