import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FaTasks, FaStickyNote } from "react-icons/fa";
import { FaOutdent } from "react-icons/fa";
import ContentLabs from "./ContentLabs";
import { MenuLab } from "components/layout/MenuLab";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SideBar = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 21rem;
  max-width: 21rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin-right: 2rem;
  border-radius: 5px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const SideBarTitle = styled.div`
  height: 4rem;
  padding: 1rem 0.5rem 1rem 1rem;
  font-size: 1.5rem;
  font-weight: bold;
`;

const SideBarContent = styled.div`
  display: flex;
  color: black;
  align-items: center;
  padding: 1.5rem;
  list-style: none;
  height: 3.5rem;
  text-decoration: none;
`;

const SidebarLabel = styled.button`
  margin-left: 1rem;
  font-size: 1rem;
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }

  &:disabled {
    cursor: not_allowed;
    color: gray;
  }
`;

const Content = styled.div`
  max-height: calc(100vh - 9rem);
  overflow-y: scroll;
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  padding: 1rem;
`;

const ShowMenu = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

const Profile = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [active_component, setActiveComponent] = useState("my_labs__result");
  const { t } = useTranslation();

  const handleChange = (e) => {
    setActiveComponent(e.target.name);
  };

  let menu;

  if (showMenu) {
    menu = <MenuLab setActiveComponent={setActiveComponent} setShowMenu={setShowMenu} showMenu={showMenu} />
  }

  return (
    <Container>
        <ShowMenu onClick={() => setShowMenu(!showMenu)}>
          <FaOutdent size={35} color='#005487' />
        </ShowMenu>
      <SideBar>
        <SideBarTitle data-testid="profile-header">{t("my_labs")}</SideBarTitle>
        <SideBarContent>
          <FaTasks />
          <SidebarLabel name="my_labs__result" onClick={handleChange}>
            {t("my_labs__result")}
          </SidebarLabel>
        </SideBarContent>

        <SideBarContent>
          <FaStickyNote />
          <SidebarLabel name="my_labs__request" onClick={handleChange}>
            {t("my_labs__request")}
          </SidebarLabel>
        </SideBarContent>
      </SideBar>
        { menu }
      <Content>
        <ContentLabs isActive={active_component} />
      </Content>
    </Container>
  );
};

export default Profile;
