import React from "react";
import styled from "styled-components";
export const Content = styled.div`
  background-color: #f66;
  padding: 0.4rem;
  margin-top: 1rem;
  color: #fff;
  font-weight: bold;
`;

/***
 * Componente de Error
 * @param {string} message Recibe un parametro
 * @returns un componente con el mensaje de error solicitado
 */
const Error = ({ message }) => {
  if (!message) return null;
  return <Content dangerouslySetInnerHTML={{__html: message}}></Content>;
};
export default Error;
