import axiosClient from "../config/axios";
/***
 *Función para generar un string y se pueda guardar el token en el Local Storage
 */
export const login = async (data) => {
  let result = {};
  await axiosClient
    .post("/auth/login/", data)
    .then((response) => {
      result = {
        status: "ok",
        result: response.data.result,
      };
    })
    .catch((error) => {
      try {
        result = {
          status: "error",
          message: error.response.data.result.message,
        };
      } catch (e) {
        result = {
          status: "error",
          message: "auth__server_error",
        };
      }
    });
  return result;
};
