/***
 * Component to change mail
 */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Error from "components/common/Error";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  requestInfoUpate,
  getRequestInfoList,
} from "data_services/UserActions";
import i18n from "i18next";

let origin = window.location.pathname.split("/")[1];

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Alert = styled.div`
  display: flex;
  color: red;
  padding-left: 2rem;
`;

export const Form = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;

  @media (max-width: 768px) {
    width: 100%;
  }

  h1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
  }

  form {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      svg {
        position: absolute;
        margin: 0 -1rem;
        width: 2rem !important;
      }

      :nth-child(3) {
        justify-content: space-between;
      }
    }

    button {
      :disabled {
        cursor: not-allowed;
        opacity: 0.5;
        background-color: #7e8084;
      }

      background-color: #262526;
      border: none;
      color: white;
      padding: 0.5rem;
      width: 8rem;
      cursor: pointer;
      margin: 0.5rem 0;
    }

    textarea {
      width: 100%;
      padding: 0.8rem 0.5rem;
      margin: 0.5rem 0;

      :focus {
        outline: none;

        border: 0.2rem solid var(--excel-primary-color);
        margin: 0.2rem;
      }
    }
  }
`;

const Counter = styled.span`
  background-color: var(--excel-primary-color);
  color: white;
  padding: 3px 9px !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 50%;
  font-size: 0.75em;
`;

const CounterMessage = styled.p`
  text-align: end;
`;

export const UserRequestChangeData = () => {
  const [pendingRequest, setPendingRequest] = useState(0);
  const { t } = useTranslation();

  /***
   * Funcion que crea un componente que muestre campos del formulario
   */
  const [request_body, setRequestBody] = useState({
    request_body: "",
  });

  /***
   * Error component is initialized as false
   */
  const [error, setError] = useState({
    status: false,
    message: null,
  });

  /***
   * funcion que interactua con los inputs para acceder a los valores value
   * @param {object} e accede al valor del input
   */
  const handleChange = (e) => {
    setRequestBody({
      ...request_body,
      [e.target.name]: e.target.value,
      language: i18n.language,
    });
  };

  const fill_pending = async () => {
    let pending = await getRequestInfoList();
    let pending_list = pending.result;
    setPendingRequest(pending_list.length);
  };

  const sendEmail = async (e) => {
    let button = e.target;
    button.setAttribute("disabled", "disabled");
    let result = await requestInfoUpate(request_body);
    if (result.status === "ok") {
      setError({
        status: false,

        message: null,
      });
      if (origin === "excel") {
        toast.success(t("profile__send_request_excel"));
      } else {
        toast.success(t("profile__send_request"));
      }
      fill_pending();
      button.removeAttribute("disabled");
      setRequestBody({
        request_body: "",
      });
    } else {
      setError({
        status: result.status,
        message: t(result.message),
      });
      toast.error(error.message);
    }
  };

  //validar que el usuario llene todos los campos
  const validate = () => {
    if (
      request_body.request_body.length < 10 ||
      request_body.request_body.length > 350
    ) {
      return false;
    }
    return true;
  };

  useEffect(() => {
    fill_pending();
  }, []);

  const { message, status } = error;

  return (
    <Container>
      <ToastContainer />
      <Form origin={origin}>
        <h1>{t("request_info__title")}</h1>
        <form>
          <div>
            <textarea
              onChange={handleChange}
              rows="3"
              name="request_body"
              data-testid="request-body-input"
              className="u-full-width"
              value={request_body.request_body}
              required
            ></textarea>
          </div>
          <CounterMessage>{request_body.request_body.length}</CounterMessage>
          <div>
            <button
              type="button"
              onClick={sendEmail}
              data-testid="submit-button"
              className="u-full-width button-primary"
              disabled={!validate()}
            >
              {t("change_password__button")}
            </button>
            {!validate() && <Alert>{t("profile__request_body_error")}</Alert>}
          </div>
        </form>
      </Form>
      {status && <Error message={message} />}
    </Container>
  );
};
