/***
 * Footer with description
 */
import React from "react";
import { useTranslation } from "react-i18next";

const Footer = ({ no_background }) => {
  /***
   * method to get current year
   * @returns current year
   */
  const getActualYear = () => {
    return new Date().getFullYear();
  };

  const { t } = useTranslation();

  return (
    <div className={`basepage__footer ${no_background && "white-background"}`}>
     <p> Hospital Excel © 1994-{getActualYear()}. {t("footer")} Av. Paseo de los Heroes
      2507, Zona Rio, Tijuana BC | <a className="number-tel" href="tel:664-633-8300">664-633-8300</a>
      </p>
    </div>
  );
};

export default Footer;
