import React, {useContext, useEffect, useState} from "react"
import styled from "styled-components";
import {toast, ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useTranslation} from "react-i18next";
import {sendAppointments} from "../../../data_services/Appointments/SendAppointment";
import {authContext} from "../../../context/authContext";
import { Language } from "@material-ui/icons";
import i18n from "i18next";

let origin = window.location.pathname.split("/")[1];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  max-height: calc(100vh - 15rem);
  height: calc(100vh - 15rem);
  background-color: white;
  display: flex;
  flex-direction: column;
  max-width: 50%;
  width: 50%;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  padding: 1rem;
  overflow-y: scroll;
`;

export const Form = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem 1rem;

  h1 {
    text-align: center;
    margin: 0;
  }

  form {
    display: flex;
    flex-direction: column;

    button {
      :disabled {
        cursor: default;
        background-color: #7e8084;
      }

      background-color: #262526;
      border: none;
      color: white;
      padding: 0.5rem;
      width: 8rem;
      cursor: pointer;
      margin: 0.5rem 0;
    }

    label {
      display: inline-block;
      margin: 0.5rem 0;
      padding: 0.7rem 0.5rem;
      font-weight: 600;
      background: #262526;
      color: white;
    }

    input, textarea, select {
      width: 100%;
      padding: 0.8rem 0.5rem;
      margin: 0.5rem 0;
      border: none;
      border-bottom: 0.2rem solid #7e8084;

      :focus {
        outline: none;
        border: 0.2rem solid #7e8084;
        margin-bottom: 0.4rem;
      }
    }
    
    select{
      background: transparent;
    }
  }
`;

const AgreementText = styled.p`
  font-size: 0.85rem;
  margin-top: 0.5rem;
  color: gray;
  text-align: justify;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 0 1 auto;
  width: 100%;
`;

const RowR = styled.div`
  display: flex;
  flex-direction: row-reverse;
  column-gap: 1rem;
  flex-wrap: wrap;
  flex: 0 1 auto;
  width: 100%;
  margin-top: 10px;
`;

const Col4 = styled.div`
  flex: 0 0 auto;
  width: 33.33%;
  padding: 0 5px 0 5px;
`;

const Col12 = styled.div`
  flex: 0 0 auto;
  width: 100%;
  padding: 0 5px 0 5px;
`;

const Col6 = styled.div`
  flex: 0 0 auto;
  width: 50%;
  padding: 0 5px 0 5px;
`;

const Counter = styled.p`
    text-align: end;
`;

const CreateAppointment = () => {

    const authState = useContext(authContext);
    const {user} = authState
    const [inputType, setInputType] = useState("text");
    const [appointment_data, setAppointmentData] = useState({
        specialty: "",
        doctor: "",
        location: "",
        reason: "",
        date: "",
        time: "",
        date_second: "",
        time_second: "",
        date_third: "",
        time_third: "",
        patient: "",
    })
    const [error, setError] = useState({
        status: false,
        message: null,
    });

    const {t} = useTranslation()

    const SPECIALTY = [
        {name: t("specialty"), id: 0},
        {name: t("appoiment_specialty__primary"), id: 1},
        {name: t("appoiment_specialty__pediatrics"), id: 2},
        {name: t("appoiment_specialty__gynecology"), id: 3},
        {name: t("appoiment_specialty__nutrition"), id: 4},
        {name: t("appoiment_specialty__psychology"), id: 5},
        {name: t("appoiment_specialty__dental"), id: 6},
        {name: t("appoiment_specialty__vision"), id: 7},
    ]
  

    const DOCTOR = [
        {name: t("doctor"), id: 0},
        {name: t("appoiment_doctor__regular"), id: 1},
        {name: t("appoiment_doctor__next"), id: 2},
    ]

    const LOCATION = [
        {name: t("location"), id: 0},
        {name: "Tijuana", id: 1},
        {name: "Mexicali", id: 2},
    ]

    const TIME = [
        {name: t("time"), id: 0},
        {name: "7:15 AM", id: 1},
        {name: "8:15 AM", id: 2},
        {name: "9:15 AM", id: 3},
        {name: "10:15 AM", id: 4},
        {name: "11:15 AM", id: 5},
        {name: "12:15 PM", id: 6},
        {name: "1:15 PM", id: 7},
        {name: "2:15 PM", id: 8},
        {name: "3:15 PM", id: 9},
        {name: "4:15 PM", id: 10},
        {name: "5:15 PM", id: 11},
        {name: "6:15 PM", id: 12},
        {name: "7:15 PM", id: 13},
    ]

    const handleChange = (e) => {
        setAppointmentData({
            ...appointment_data,
            [e.target.name]: e.target.value,
            patient: user,
            "language":i18n.language
        });
    };




    const changeType = (e) => {
        if (inputType === "text") {
            setInputType("date");
        } else {
            setInputType("text");
        }
    };

    const submitAppointment = async (e) => {
        e.preventDefault();
        let button = document.getElementById('submit_btn')
        button.setAttribute("disabled", "disabled");
        let result = await sendAppointments(appointment_data)
        if (result.status === "error") {
            setError({
                status: true,
                message: t(result.message),
            });
            button.removeAttribute("disabled");
            toast.error(error.message)
            setTimeout(() => {
                window.location.href = `/${origin}/my-appointments/`;
            }, 3000)
        } else {
            toast.success(t("send_done"));
            setTimeout(() => {
                window.location.href = `/${origin}/my-appointments/`;
            }, 3000)
        }
    };

    //validar que todos los campos esten llenos
    const validate = () => {
        let valid = true;
        if (appointment_data.specialty === "" || appointment_data.specialty === t("specialty")) {
            valid = false;
        }
        if (appointment_data.doctor === ""  || appointment_data.doctor === t("doctor")) {
            valid = false;
        }
        if (appointment_data.location === "" || appointment_data.location === t("location")) {
            valid = false;
        }
        if (appointment_data.reason === "" || appointment_data.reason.length > 250) {
            valid = false;
        }
        if (appointment_data.date === "" ) {
            valid = false;
        }
        if (appointment_data.time === "" || appointment_data.time === t("time")) {
            valid = false;
        }
        if (appointment_data.date_second === "") {
            valid = false;
        }
        if (appointment_data.time_second === "" || appointment_data.time_second === t("time")) {
            valid = false;
        }
        if (appointment_data.date_third === "") {
            valid = false;
        }
        if (appointment_data.time_third === "" || appointment_data.time_third === t("time")) {
            valid = false;
        }
        return valid;
    };

    useEffect(() => {
        if (validate()) {
            document.getElementById('submit_btn').removeAttribute("disabled");
        } 
    }, [appointment_data]);


    return (
        <Container>
            <ToastContainer
                position="top-right"
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover/>
            <Content>
                <Form origin={origin}>
                    <h1>{t("new_appointment")}</h1>
                    <form onSubmit={submitAppointment}>
                        <Row>
                            <Col4>
                                <>
                                    <select
                                        onChange={handleChange}
                                        name="specialty"
                                        list="specialty"
                                        placeholder={t("specialty")}
                                        type="text"
                                    >
                                        {SPECIALTY.map(option => (
                                            <option value={option.name}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            </Col4>
                            <Col4>
                                <>
                                    <select
                                        onChange={handleChange}
                                        name="doctor"
                                        list="doctor"
                                        placeholder={t("doctor")}
                                        type="text"
                                    >
                                        {DOCTOR.map(option => (
                                            <option value={option.name}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            </Col4>
                            <Col4>
                                <>
                                    <select
                                        onChange={handleChange}
                                        name="location"
                                        list="location"
                                        placeholder={t("location")}
                                        type="text"
                                    >

                                        {LOCATION.map(option => (
                                            <option value={option.name}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            </Col4>
                        </Row>
                        <Row>
                            <Col12>
                                <textarea
                                    onChange={handleChange}
                                    name="reason"
                                    placeholder={t("reason")}
                                    rows={4}
                                />
                                <Counter>{appointment_data.reason.length}/250</Counter>
                            </Col12>
                        </Row>
                        <Row>
                            <Col6>
                                <input
                                    onChange={handleChange}
                                    type={inputType}
                                    name="date"
                                    onFocus={changeType}
                                    onBlur={changeType}
                                    placeholder={t("date")}
                                />
                            </Col6>
                            <Col6>
                                <>
                                    <select
                                        onChange={handleChange}
                                        name="time"
                                        list="time"
                                        type="text"
                                    >
                                        {TIME.map(option => (
                                            <option value={option.name}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            </Col6>
                        </Row>
                        <label>{t("second_option")}</label>
                        <Row>
                            <Col6>
                                <input
                                    onChange={handleChange}
                                    type={inputType}
                                    name="date_second"
                                    onFocus={changeType}
                                    onBlur={changeType}
                                    placeholder={t("date")}
                                />
                            </Col6>
                            <Col6>
                                <>
                                    <select
                                        onChange={handleChange}
                                        name="time_second"
                                        list="time"
                                        type="text"
                                    >
                                        {TIME.map(option => (
                                            <option value={option.name}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            </Col6>
                        </Row>
                        <label>{t("third_option")}</label>
                        <Row>
                            <Col6>
                                <input
                                    onChange={handleChange}
                                    type={inputType}
                                    name="date_third"
                                    onFocus={changeType}
                                    onBlur={changeType}
                                    placeholder={t("date")}
                                />
                            </Col6>
                            <Col6>
                                <>
                                    <select
                                        onChange={handleChange}
                                        name="time_third"
                                        list="time"
                                        type="text"
                                    >
                                        {TIME.map(option => (
                                            <option value={option.name}>
                                                {option.name}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            </Col6>
                        </Row>
                        <RowR>
                            <button
                                type="submit"
                                id="submit_btn"
                                disabled = {!validate()}
                            >
                                {t("change_password__button")}
                            </button>
                        </RowR>
                        <Row>
                            <AgreementText origin={origin}>
                                {t("newapp__agreement_text")}
                            </AgreementText>
                        </Row>
                    </form>
                </Form>
            </Content>
        </Container>
    )
}

export default CreateAppointment