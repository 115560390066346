/***
 * This components contains a parameter that contain
 * a name to change the component
 */
import React, {useContext, useEffect} from "react";
import { UserData } from "./UserData";
import { UserChangeMailData } from "./UserChangeMailData";
import { UserContactData } from "./UserContactData";
import { UserRequestChangeData } from "./UserRequestChangeData";
import { authContext } from "context/authContext";
import axiosClient from "config/axios";

const DataContent = ({ isActive }) => {
  const user_personal_data = "user-personal-data";
  const user_contact_data = "user-contact-data";
  const user_change_mail_data = "user-change-mail-data";
  const user_request_update_info = "user-request-update-info";
  const PAGES = {
    [user_personal_data]: <UserData />,
    [user_contact_data]: <UserContactData />,
    [user_change_mail_data]: <UserChangeMailData />,
    [user_request_update_info]: <UserRequestChangeData />,
  };
  const authState = useContext(authContext);
  const { setLogout } = authState;

  /**
   * Validates the user session
   * @date 2022-07-06
   */
  const validateSession = () => {
    axiosClient
      .get("/auth/userdata/")
      .then((response) => {
      })
      .catch((error) => {
        setLogout();
      });
  }

  useEffect(() => {
    validateSession();
  }, [isActive])

  return PAGES[isActive];
};

export default DataContent;
