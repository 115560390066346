import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import Appointments from "./Appointments";

let origin = window.location.pathname.split("/")[1];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

const Content = styled.div`
  max-height: calc(100vh - 9rem);
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
  padding: 1rem;
`;

const Row = styled.div`
  --gutter-x: 1.5rem;
  --gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: end;
  margin-top: calc(var(--gutter-y) * -1);
  margin-bottom: .5rem;
  margin-right: calc(var(--gutter-x) * -.5);
  margin-left: calc(var(--gutter-x) * -.5);
  width: 100%;
`;

const Button = styled(Link)`
  background-color: #7e8084;
  border: none;
  padding: 1rem;
  color: white;
  text-decoration: none;

  &:hover {
    background-color: #262526;
    cursor: pointer;
  }
`;

const AppointmentsIndex = () => {
    const {t} = useTranslation()
    return (
        <Container>
            <Row>
                <Button to={`/${origin}/my-appointments/create/`}>
                    {t("schedule_appointment")}
                </Button>
            </Row>
            <Content>
                <Appointments/>
            </Content>
        </Container>
    )
}

export default AppointmentsIndex