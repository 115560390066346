/***
 * Provider list
 */

import React, { Fragment, useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { getProviders } from "data_services/HealthPlan/Providers";
import TabsComponent from "components/common/TabsComponent";
import Provider from "./Provider";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { MenuContext } from "context/menuContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Content = styled.div`
  display: ${(props) => (props.active ? "grid" : "none")};
  justify-content: ${(props) => (props.active ? "center" : "center")};
  grid-template-columns: repeat(4, minmax(200px, 300px));
  grid-gap: 1rem;

  @media only screen and (min-width: 1201px) and (max-width: 1650px) {
    grid-template-columns: repeat(3, minmax(200px, 300px));
    grid-gap: 0.5rem;
  }

  @media only screen and (max-width: 1200px) {
    grid-template-columns: repeat(1, minmax(200px, 300px));
  }
`;

const Message = styled.p`
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-align: center;
  font-size: 1.2rem;
`;

const Loading = styled(AiOutlineLoading3Quarters)`
  color: #005487;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  align-items: center;
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const Title = styled.h1``;

const MedicalProviders = () => {
  /***
   * 1 Tijuana
   * 2 San Diego
   * 3 Mexicali
   * 4 Imperial
   */
  const { showVideo } = useContext(MenuContext);
  const [active, setActive] = useState(0);
  const [all_providers, setAllProviders] = useState([]);
  const [providers, setProviders] = useState([]);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = (e) => {
    setActive(parseInt(e.target.id, 0));
  };

  useEffect(() => {
    fetchProviders();
  }, []);

  useEffect(() => {
    filterProviders();
  }, [active, all_providers]);

  /***
   * filter providers
   */
  const filterProviders = () => {
    const tmp_providers = [];
    all_providers.forEach((item) => {
      if (active === 0) {
        if (item.region === 1 || item.region === 2) {
          tmp_providers.push(item);
        }
      } else {
        if (item.region === 3 || item.region === 4) {
          tmp_providers.push(item);
        }
      }
    });

    setProviders(tmp_providers);
  };

  const fetchProviders = async () => {
    let result = await getProviders();
    let tmp_data = [];
    let tmp = result.result;
    tmp.forEach((item) => {
      let tmp_address = item.address.replace("*", "<br/>" );
      let tmp_address_sp = item.address_spanish.replace("*", "<br/>" );
      let tmp_working_hours = item.working_hours.replaceAll("*", "<br/>" );
      let tmp_working_hours_sp = item.working_hours_spanish.replaceAll("*", "<br/>" );
      tmp_data.push({...item, address: tmp_address, address_spanish: tmp_address_sp, working_hours: tmp_working_hours, working_hours_spanish: tmp_working_hours_sp});
    })
    setAllProviders(tmp_data);
    setIsLoading(false);
  };

  const Tabs = [
    { name: "Tijuana / Tecate / San Diego" },
    { name: `${t("my_health_plan__city")}` },
  ];

  return (
    <Fragment>
      <Container>
        <div className={showVideo ? 'visible' : 'hidden'}>
          <TabsComponent handle={handleClick} active={active} TabsName={Tabs} />
        </div>
        <Message>{t("my_health_medical_providers__911")}</Message>
        {!isLoading ? (
          <>
            <Content active={active === 0}>
              <Provider providers={providers} />
            </Content>
            <Content active={active === 1}>
              <Provider providers={providers} />
            </Content>
          </>
        ) : (
          <Loading size={100} />
        )}
      </Container>
    </Fragment>
  );
};

export default MedicalProviders;
