import axiosClient from "../config/axios";

export const signup = async (data) => {
  let result = {};
  await axiosClient
    .post("/auth/signup/", data)
    .then((response) => {
      result = {
        status: "ok",
        result: response.data.result,
      };
    })
    .catch((err) => {
      try {
        result = {
          status: "error",
          message: err.response.data.result.message,
        };
      } catch {
        result = {
          status: "error",
          message: "auth__server_error",
        };
      }
    });
  return result;
};
