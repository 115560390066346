/***
 * Component that contain personal data
 */
import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { authContext } from "context/authContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { requestInfoUpate } from "data_services/UserActions";
import i18n from "i18next";
import { login } from "data_services/Login";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;
`;

const ImageContainer = styled.div`
  background-color: gray;
  display: flex;
  width: 250px;
  height: 250px;
  margin-top: 1rem;
  border-radius: 1rem;
`;
const Label = styled.p`
  font-size: 1.5rem;
  margin-top: 1rem;


  span {
    font-weight: bold;
    /* text-align: left; */
  }

  li {
    font-weight: bold;
    list-style-type: none;
  }
`;
const Labell = styled.p`
  font-size: 1.5rem;
  margin-top: 3rem;
`;





const Buttons = styled.div`
  display: flex;
  margin-top: 10px;
  button:nth-child(1) {
    margin-right: 20px;
  }
  button {
    background-color: #005487;
    padding: 10px;
    border: none;
    color: white;

    &:hover {
      background-color: #003251;
      cursor: pointer;
    }

    &:disabled {
      opacity: 0.7;
      cursor: not-allowed;
    }
  }
`;

export const UserInfo = () => {
  const { t } = useTranslation();
  const authState = useContext(authContext);
  const { user } = authState;

  const sendUserRequest = async (e, body, message) => {
    let button = e.target;
    button.setAttribute("disabled", "disabled");
    let result = await requestInfoUpate({
      request_body: body,
      language: i18n.language,
    });
    if (result.status === "ok") {
      toast.success(t(message));
      button.removeAttribute("disabled");
    }
  };

  return (
    <Container>
      <ToastContainer />
      <SubContainer>
        <Label>
          {t("signup__placeholder_first_name")}: <span>{user.first_name}</span>
        </Label>
        <Label>
          {t("signup__placeholder_last_name")}: <span>{user.last_name}</span>
        </Label>
        <Label>
          {t("signup__placeholder_date_of_birth")}:{" "}
          <span>{user.date_of_birth}</span>
        </Label>
        <Label>
          {t("my_health_plan__placeholder_credentials")}:{" "}
          {user.credentials &&
            user.credentials.map((item) => {
              if(item.active) return <span>{item.id_number}</span>;
            })}
        </Label>
        <Labell>{t("my_health_plan__document_request")}</Labell>
      
      <Buttons>
        <button
          onClick={(e) => sendUserRequest(e, "Form 1095B", "request__1095b")}
        >
          1095B
        </button>
        <button
          onClick={(e) =>
            sendUserRequest(e, "Certificate of Coverage", "request__CoC")
          }
        >
          {t("my_health_plan__certificate")}
        </button>
      </Buttons>{" "}
      </SubContainer>
    </Container>
  );
};

export default UserInfo;
