import React, {useContext, useEffect} from "react";
import { authContext } from "context/authContext";
import LabsRequests from "./LabsRequests";
import LabsResult from "./LabsResult";
import axiosClient from "config/axios";

const ContentLabs = ({ isActive }) => {
  const PAGES = {
    ["my_labs__request"]: <LabsRequests />,
    ["my_labs__result"]: <LabsResult />,
  };

  const authState = useContext(authContext);
  const { setLogout } = authState;

  /**
   * Validates the user session
   * @date 2022-07-06
   */
  const validateSession = () => {
    axiosClient
      .get("/auth/userdata/")
      .then((response) => {
      })
      .catch((error) => {
        setLogout();
      });
  }

  useEffect(() => {
    validateSession();
  }, [isActive])

  return PAGES[isActive];
};

export default ContentLabs;
