/***
 * Component that contain personal data
 */
import React, { useContext } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { authContext } from "context/authContext";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Label = styled.p`
  font-size: 1.5rem;
  margin-top: 1rem;

  span {
    font-weight: bold;
  }
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: left;

  @media (max-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const UserData = () => {
  const { t } = useTranslation();
  const authState = useContext(authContext);
  const { user } = authState;

  return (
    <Container>
      <SubContainer>
        <Label>
          {t("signup__placeholder_first_name")}:<span> {user.first_name} </span>
        </Label>
        <Label>
          {t("signup__placeholder_last_name")}:<span> {user.last_name} </span>
        </Label>
        <Label>
          {t("signup__placeholder_date_of_birth")}: <span> {user.date_of_birth} </span>
        </Label>
      </SubContainer>
    </Container>
  );
};
