/***
 * Navbar to show user and icon with css class basepage__navbar
 */
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { layoutContext } from "context/layoutContext";
import { authContext } from "context/authContext";
import {
  FaUserAlt,
  FaUserCircle,
  FaSignOutAlt,
  FaEllipsisV,
  FaHome,
} from "react-icons/fa";
import { LanguageButton } from "../common/LanguageButon";
import { MenuContext } from "context/menuContext";
import { Link } from "react-router-dom";
import excel_logo_topleftheader from "assets/images/excel_logo_topleftheader.png";
import mediexcel_logo_topleftheader from "assets/images/mediexcel_logo_topleftheader.png";

const NavbarContent = styled.div`
  /* background-color: ${({ health_plan }) => health_plan === "my-health-plan" ? "#005487" : "#fff"}; */
  background: #fff;
`;

const MenuUser = styled.div`
  color: ${({ health_plan }) =>
    health_plan === "my-health-plan" ? "#fff" : "#000"};
  font-size: 1.5rem;
  display: flex;
  align-items: center;

  &:hover {
    & > div {
      display: block;
      top: 5rem;
    }
  }
`;

const Menu = styled.div`
  display: flex;
  justify-content: center;
  justify-items: center;
  background: ${({ health_plan }) =>
    health_plan === "my-health-plan" ? "#005487" : "#b0b3b8"};
  padding: 0.5rem;
  margin-right: 15px;
  &:hover {
    cursor: pointer;
  }
`;

const FloatMenu = styled.div`
  width: 200px;
  height: 300px;
  background: #0000;
  position: absolute;
  right: 25px;
  display: none;
  z-index: 1;

  &:hover {
    cursor: pointer;
  }
`;

const FloatContent = styled.div`
  width: 200px;
  background: ${({ health_plan }) =>
    health_plan === "my-health-plan" ? "#005487" : "#d8d9da"};
  position: absolute;
  box-shadow: 2px 2px 5px #999;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
`;

const FloatContentItem = styled(Link)`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.7rem 1.3rem;
  width: 100%;
  color: ${({ health_plan }) =>
    health_plan === "my-health-plan" ? "white" : "black"};
  text-decoration: none;
  font-size: 1rem;

  &:hover {
    border-left: 4px solid
      ${({ health_plan }) =>
        health_plan === "my-health-plan" ? "#93d500" : "#7e8084"};
    font-weight: bold;
  }

  & > svg {
    margin-right: 1rem;
    //espacio entre icono y texto
    margin-left: 0.5rem;

  }
`;

const FloatLastContentItem = styled.a`
  display: flex;
  align-items: center;
  position: relative;
  padding: 0.7rem 1.3rem;
  width: 100%;
  color: ${({ health_plan }) =>
    health_plan === "my-health-plan" ? "white" : "black"};
  text-decoration: none;
  font-size: 1rem;
  margin-top: 1.2rem;

  &:hover {
    border-left: 4px solid
      ${({ health_plan }) =>
        health_plan === "my-health-plan" ? "#93d500" : "#7e8084"};
    font-weight: bold;
  }

  & > svg {
    margin-right: 1rem;
  }
`;

const Separator = styled.div`
  content: "";
  position: absolute;
  left: 8%;
  width: 84%;
  height: 1px;
  z-index: 1;
  background: ${({ health_plan }) =>
    health_plan === "my-health-plan" ? "#ccc" : "#000"};
  margin-top: 0.7rem;
`;

const UserName = styled.p`
  color: ${({ health_plan }) =>
    health_plan === "my-health-plan" ? "#005487" : "#000"};
  display: flex;
  align-items: center;
  font-size: 2rem;
  font-weight: bold;
  margin-right: 1rem;

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;
const UserContainer = styled.div`
  display: flex;
`;
const LogoContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: ${({ origin }) => (origin === "mediexcel" ? "500" : "400")}px;

  & > img::first-child {
    margin-right: 1rem;
  }

  & > a {
    color: ${({ health_plan }) =>
      health_plan === "my-health-plan" ? "#fff" : "#000"};
    background-color: ${({ health_plan }) =>
      health_plan === "my-health-plan" ? "#005487" : "#b0b3b8"};
    padding: 0rem 0.5rem;
  }
`;
const Logo = styled.img`
  object-fit: contain;
  width: 180px;
  &:first-child {
    margin-right: 10px;
  }
  &:last-child {
    width: 300px;
    padding: 1rem;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const LogoOnlyMediExcel = styled.img`
  display: none;
  object-fit: contain;
  width: 180px;

  @media (max-width: 768px) {
    display: block;
  }

  @media (max-width: 640px) {
    width: 100px;
  }
`;

const LanguageSection = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 2rem;
  border-bottom: #ccc 1px solid;
  padding-right: 1rem;
`;

const UserSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4.5rem;
  padding: 0 1rem;
`;

const LastSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 3rem;
  background: ${({ health_plan }) =>
    health_plan === "my-health-plan" ? "#005487" : "#b0b3b8"};
`;

const HomeLink = styled(Link)`
  display: flex;
  align-items: center;
  color: ${({ health_plan }) =>
    health_plan === "my-health-plan" ? "#005487" : "#b0b3b8"};
    
`;

const Navbar = () => {
  const layoutState = useContext(layoutContext);
  const authState = useContext(authContext);
  const { showMenu, setShowMenu } = useContext(MenuContext);
  const { origin } = layoutState;
  const { user, setLogout } = authState;
  const { t } = useTranslation();
  const [health_plan, setHealthPlan] = useState("");

  useEffect(() => {
    let url = window.location.pathname;
    let sub = url.split("/");
    setHealthPlan(sub[2]);
  }, [window.location.pathname]);

  useEffect(() => {
  }, [health_plan]);

  const logut = () => {
    setLogout();
  };

  return (
    <NavbarContent health_plan={health_plan} className="basepage__navbar">
      <LanguageSection>
        <LanguageButton />
      </LanguageSection>
      <UserSection>
        <LogoContainer health_plan={health_plan} origin={origin}>
          <Logo src={origin === "mediexcel" ? mediexcel_logo_topleftheader : excel_logo_topleftheader} />
          {origin === "mediexcel" && <Logo src={excel_logo_topleftheader} alt="Logo" />}
          <LogoOnlyMediExcel src={mediexcel_logo_topleftheader} alt="Logo" />
        </LogoContainer>
        <UserContainer>
          <UserName health_plan={health_plan}>
            {t("navbar__welcome")}, {user.first_name}!
          </UserName>

          <MenuUser health_plan={health_plan}>
            <Menu health_plan={health_plan}>
              <FaUserAlt />
              <FaEllipsisV />
            </Menu>
            <FloatMenu>
              <FloatContent health_plan={health_plan}>
                <nav>
                  <div>
                    <FloatContentItem
                      onClick={() => {
                        window.location.href = `/${origin}/profile`;
                      }}
                      health_plan={health_plan}
                    >
                      <FaUserCircle />
                      {t("navbar__profile")}
                    </FloatContentItem>
                    <Separator health_plan={health_plan} />
                    <FloatLastContentItem
                      onClick={() => logut()}
                      health_plan={health_plan}
                    >
                      <FaSignOutAlt />
                      {t("navbar__logout")}
                    </FloatLastContentItem>
                  </div>
                </nav>
              </FloatContent>
            </FloatMenu>
            
          </MenuUser>
          <HomeLink to={`/${origin}/`} health_plan={health_plan}>
            <FaHome onClick={() => { setShowMenu(false) }} size={35} />
          </HomeLink>
        </UserContainer>
      </UserSection>
      <LastSection health_plan={health_plan} />
    </NavbarContent>
  );
};

export default Navbar;
