import axiosClient from "config/axios";
/***
 * get coverages from backend
 */
export const getAppointments = async (data) => {
    let result = {};
    await axiosClient
        .get("/schedule/appointments/", data)
        .then((response) => {
            result = {
                error: false,
                result: response.data,
            };
        })
        .catch((error) => {
            result = {
                error: true,
                message: "auth__server_error",
            };
        });
    return result;
};
