export const translationEN = {
  //login
  portal_patient: "Patient Portal",
  login__title: "Login",
  login__placeholder_email: "Email",
  login__placeholder_password: "Password",
  login__button: "Login",
  login__fields: "All fields are required",
  login__is_not_mediexcel:
    "Your MediExcel coverage is no longer active. \n" +
    "Click on the link <a href='/excel/login/'>Go to Excel</a> to access your profile as an Excel Hospital patient. \n" +
    "Having Trouble? Contact sales@mediexcel.com",
  login__is_not_excel:
    "You have an active coverage. \n" +
    "Click on the link <a href='/mediexcel/login/'>Go to MediExcel</a> to access your profile as a MediExcel patient. \n" +
    "Having Trouble? Contact sales@mediexcel.com",
  login__email_confirm_pending:
    "Your email address hasn’t been verified." +
    "Having trouble? Contact sales@mediexcel.com",
  login__mediexcel_expired: "Mediexcel coverage expired",
  login__excel_new_coverage: "Excel New Coverage",
  login__account_not_found: "Account Not Found",
  login__invalid_credentials: "Invalid Credentials",
  signup_forgot_password: "I forgot my password",
  powered__by:" Operated By",
  powered__by_portal:" Patient Portal",
  //signup
  signup__title: "Sign Up",
  signup__placeholder_first_name: "First Name",
  signup__placeholder_last_name: "Last Name",
  signup__placeholder_date_of_birth: "Date of birth",
  signup__placeholder_member: "Member No.",
  signup__placeholder_telephone: "Telephone",
  signup__placeholder_confirm_password: "Confirm Password",
  signup__button: "Sign Up",
  signup__password_short: "Password too short",
  signup__password_match: "Passwords do not match",
  signup__invalid_name: "Invalid Name",
  signup__invalid_credential:
    "The MediExcel member number provided is invalid. " +
    "Please verify or switch to the Hospital Excel account if you are a private patient.",
  signup__invalid_password: "Invalid Password",
  signup__invalid_phone: "Invalid Phone",
  signup__invalid_patient: "Invalid Patient",
  signup__invalid_info:
    "The information provided is not valid the user data does not exist",
  signup__existing_patient_portal: "Existing Patient Portal",
  signup__existing_user_email: "Existing User Email",
  signup__account_create: "Account Create",
  signup__agreement_text:
    "By clicking the Sign Up button, you are agreeing to access our Portal and " +
    "receiving all documents, notices, announcements and surveys electronically, including health plan documents.",
  signup__accept_receive_email_checkbox:
    "I accept the sending of information emails",
  signup__accept_receive_email: "I accept the sending of emails",
  //forgot_passwor
  forgot_password__title: "Reset your password",
  forgot_password__message:
    "We will send you an email with instructions to modify it",
  forgot_password__button: "Send mail",
  forgot_password__input: "Please provide your email",
  //navbar
  navbar__welcome: "Welcome",
  navbar__profile: "Profile",
  navbar__logout: "Log Out",
  //postregister
  postregister__go_to_login: "Go to Login",
  postregister__message:
    "We sent you a verification email. Please confirm your email before logging in.",
  //login-sigup
  auth__invalid_email: "Invalid Email",
  auth__server_error: "Server Error",
  auth__go_to_mediexcel: "Go to MediExcel",
  auth__go_to_excel: "Go to Excel",
  //Change Password
  change_password: "Change Password",
  change_password__button: "Submit",
  change_password_confirmed: "Your password has been updated",
  change_password__placeholder_password: "New Password",
  change_password__placeholder_confirm_password: "Confirm New Password",
  change__password__message:
    "We sent you a verification email. Please confirm your email before logging in.",
  //others
  change_language_english: "English",
  change_language_spanish: "Spanish",
  footer: "All Rights Reserved.",
  developed_by: "Developed by",
  addresses: "Addresses",
  phones: "Phones",
  emails: "Emails",
  find_doctor: "//excel.network/physicians/provider-list/",
  pdf__download_credential: "Download",
  //Profile
  profile: "Profile",
  personal_data: "Personal Data",
  contact_data: "Contact Information",
  change_email: "Change Email",
  change_personal_data: "Change Personal Information",
  profile__send_request:
    "We've sent an email with your request to our Account Management Team.\n" +
    "They will get in contact with you to make the required change(s).",
  profile__send_request_excel:
    "We've sent an email with your request to our Reception Team.\n" +
    "They will get in contact with you to make the required change(s).",
  profile__counter_pendings: "Number of requests pending to resolve",
  request_info__title: "Please tell us about your request",
  changemail__email_exists:
    "The email you entered is already in use in the system",
  changemail__validation_sended:
    "Application accepted an email has been sent to verify the changes",
  change__mail_message:
    "A verification email of your account has been sent. Please validate your email",
  message__go_to_origin: "Go to home",
  //Menu
  my_mediexcel_plan: "My MediExcel",
  my_appointments: "My Appointments",
  my_labs: "My Labs",
  my_cabinets: "My Imaging Notes",
  //My Health Plan
  my_health_plan: "My MediExcel",
  my_health_plan__info: "Coverage Information",
  my_health_plan__digital_id: "View ID Card",
  my_health_plan__find_doctor: "Find a Doctor",
  my_health_plan__providers: "Urgent Care Providers",
  my_health_plan__plan: "My Plan",
  my_health_plan__videos: "Videos",
  my_health_plan__videos_no_video: "No videos available",
  my_health_plan__dependents: "My Dependents",
  my_health_plan__placeholder_credentials: "Member ID",
  my_health_plan__certificate: "Certificate of Coverage",
  my_health_plan__document_request: "Most Requested Documents",
  my_health_plan__placeholder_Name: "Name",
  my_health_plan__placeholder_DOB: "Date Of Birth",
  my_health_plan__placeholder_Medical_Plan: "My Medical Plan",
  my_health_plan__placeholder_Vision_Plan: "My Vision Plan",
  my_health_plan__placeholder_Dental_Plan: "My Dental Plan",
  my_health_plan__placeholder_Member_Number: "Member Number",
  my_health_plan__city: "Mexicali / Imperial County",
  my_dependents: "My Dependents",
  dependents_message: "Dependent Over 13 years",
  message_privacity:
    "To comply with federal and state regulations, " +
    "Mediexcel Health Plan restricts access to the personal health information of any member 13 years of age or older.",
  //My plans
  my_plans__copays: "Copays",
  my_plans__medical_plan_title: "Medical",
  my_plans__dental_plan_title: "Dental",
  my_plans__vision_plan_title: "Vision",
  my_plans__description_copay:
    "This is a partial list of benefits and copays. " +
    "Please click on the 'My Plan Documents' button below to view your Evidence of Coverage for details. " +
    "For additional questions, contact our Member Service Line at 619-365-4346 (US) or 664-633-8555 (MX).",
  my_plans__description_no_copay:
    "This is a partial list of benefits. " +
    "Please click on the 'My Plan Documents' button below to view your Evidence of Coverage for details. " +
    "For additional questions, contact our Member Service Line at 619-365-4346 (US) or 664-633-8555 (MX).",
  my_plans__document_button: "My Plan Documents",
  my_labs__request: "My Lab Orders",
  my_requests: "Request",
  my_labs__result: "My Lab Results",
  my_labs__results: "Lab Results",
  my_lab__request: "You have no Lab requests",
  my_lab__result_not_found: "You have no Lab results",
  //not found
  not_found__title: "Page not found",
  not_found__content:
    "The page you are looking for might have been removed, had its name changed or is temporarily unavailable",
  my_labs__requests: "My Lab Orders",
  my_labs__requests_type: "Type",
  my_labs__requests_date: "Date",
  my_labs__requests_studies: "# Studies",
  my_labs__requests_detail: "Detail",
  my_health_plan_dependents__not_found: "You don't have dependents.",
  my_health_medical_providers__911:
    "If you are experiencing a life-threatening emergency, dial 911 or go to your nearest Emergency Room.",
  request__1095b:
    "We are working on your request. The 1095B team will reach out to you once your form is available. \n" +
    "Please Note: If you have not received this document within 48 hours, contact 1095@mediexcel.com",
  request__CoC:
    "We are working on your request. We will reach out to you once your form is available. \n" +
    "Please Note: If you have not received this document within 48 hours, contact Applications",
  schedule_appointment: "Schedule Appointment",
  in_person: "In-Person",
  virtual_care: "Virtual Care",
  upcoming_apps: "Upcoming Appointments",
  no_apps: "No appointments currently scheduled.",
  new_appointment: "New Appointment",
  specialty: "Specialty",
  doctor: "Doctor",
  location: "Location",
  reason: "Reason for appointment *",
  date: "Date *",
  time: "Time *",
  second_option: "Second Option",
  third_option: "Third Option",
  newapp__agreement_text:
    "If you do not receive a confirmation e-mail, please e-mail Member Services at " +
    "memberservices@mediexcel.com or call 619-365-4346 (US) or 664-633-8555 (MX)",
  send_done:
    "Your appointment request has been sent to our Member Services Team. Someone will contact you shortly.",
  time_label_table: "Time",
  doctor_label_table: "Doctor",
  specialty_label_table: "Specialty",
  appoiment_specialty__primary: "Primary Care",
  appoiment_specialty__pediatrics: "Pediatrics",
  appoiment_specialty__gynecology: "Gynecology",
  appoiment_specialty__nutrition: "Nutrition",
  appoiment_specialty__psychology: "Psychology",
  appoiment_specialty__dental: "Dental",
  appoiment_specialty__vision: "Vision",
  appoiment_doctor__regular: "My Regular Doctor",
  appoiment_doctor__next: "Next Available Doctor",
  specialty: "Specialty *",
  doctor: "Doctor *",
  location: "Location *",
  loading: "Loading...",
  profile__request_body_error:
    "Please enter a minimum of 10 characters and a maximum of 350 characters",
};
