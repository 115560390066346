/***
 * User Plan Component
 */
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Plan from "./Plan";
import { getCoverages } from "data_services/HealthPlan/Coverages";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Loading = styled(AiOutlineLoading3Quarters)`
  color:#005487;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  @-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
`;

const UserPlan = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [medicalCoverages, setMedicalCoverages] = useState([]);
  const [dentalCoverages, setDentalCoverages] = useState([]);
  const [visionCoverages, setVisionCoverages] = useState([]);
  const { t } = useTranslation();

  const fetchCoverages = async () => {
    let result = await getCoverages();
    setMedicalCoverages(result.result.medical);
    setDentalCoverages(result.result.dental);
    setVisionCoverages(result.result.vision);
    setIsLoading(false);
    // setError(result.error);
  };

  useEffect(() => {
    fetchCoverages();
  }, []);

  return (
    <Container>
      {!isLoading ? (
        <>
          {medicalCoverages.hasOwnProperty("name") && (
        <Plan
          copays={medicalCoverages.copayments}
          type={t("my_plans__medical_plan_title")}
          plan={medicalCoverages}
        />
      )}
      {dentalCoverages.hasOwnProperty("name") && (
        <Plan
          copays={dentalCoverages.copayments}
          type={t("my_plans__dental_plan_title")}
          plan={dentalCoverages}
        />
      )}
      {visionCoverages.hasOwnProperty("name") && (
        <Plan
          copays={visionCoverages.copayments}
          type={t("my_plans__vision_plan_title")}
          plan={visionCoverages}
        />
      )}
        </>
      ) : (
        <>
        <Loading size={100} />
        <h1>{t("loading")}</h1>
        </>
      )}
    </Container>
  );
};

export default UserPlan;
