/***
 * Component that contain contact data
 */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { ContactComponent } from "./ContactComponent";
import axiosClient from "config/axios";


const Container = styled.div`
  display: flex;
  width: 100%;
  height: 700px;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
`;




export const UserContactData = () => {
  const { t } = useTranslation();

  const [addresses, setAddresses] = useState([]);

  const [phones, setPhones] = useState([]);

  const [emails, setEmails] = useState([]);

  /***
   * Funcion para optener los datos del usuario
   * api addresses
   */
  const getAddress = async () => {
    let result = {};
    await axiosClient
      .get(`/contacts/addresses/`)
      .then((response) => {
        // console.log("222", response.data.results);
        result = {
          status: "ok",
          result: response.data.results,
        };
        setAddresses(response.data.results);
      })
      .catch((error) => {
        try {
          result = {
            status: "error",
            message: error.response.data.results.message,
          };
        } catch (e) {
          result = {
            status: "error",
            message: "auth__server_error",
          };
        }
      });
    return result;
  };

  /***
   * Funcion para optener los datos del usuario
   * api phones
   */
  const getPhones = async () => {
    let result = {};
    await axiosClient
      .get(`/contacts/phones/`)
      .then((response) => {
        result = {
          status: "ok",
          result: response.data.results,
        };
        setPhones(response.data.results);
      })
      .catch((error) => {
        try {
          result = {
            status: "error",
            message: error.response.data.results.message,
          };
        } catch (e) {
          result = {
            status: "error",
            message: "auth__server_error",
          };
        }
      });
    return result;
  };



  /***
   * Funcion para optener los datos del usuario
   * api emails
   */
  const getEmails = async () => {
    let result = {};
    await axiosClient
      .get(`/contacts/emails/`)
      .then((response) => {
        result = {
          status: "ok",
          result: response.data.results,
        };
        setEmails(response.data.results);
      })
      .catch((error) => {
        try {
          result = {
            status: "error",
            message: error.response.data.results.message,
          };
        } catch (e) {
          result = {
            status: "error",
            message: "auth__server_error",
          };
        }
      });
    return result;
  };

  useEffect(() => {
    getAddress();
    getPhones();
    getEmails();
  }, []);

  return (
    <Container>
      <div>
      <ContactComponent  list={addresses} title={t("addresses")} />
      <ContactComponent  list={phones} title={t("phones")} />
      <ContactComponent  list={emails} title={t("emails")} />
      </div>
    </Container>
  );
};
