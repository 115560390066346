/***
 * Componente para cambiar el idioma
 */
import React, {useEffect, useState} from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import {FaLanguage} from "react-icons/fa";

let origin = window.location.pathname.split("/")[1];

/***
 * StyledComponent para el boton
 */
const Container = styled.div`
  display: flex;
  align-items: center;
  align-content: center;
  
  span{
    margin-right: 5px;
  }
`;

export const LanguageButton = () => {
  /***
   * 1. useState para el manejo del lenguaje
   * 2. Hook para cambiar el idioma
   * 3. Hook para cambiar el texto
   */
  const [selected, setSelected] = useState(false);
  const { i18n } = useTranslation();
  const [language, setLanguage] = useState(i18n.language);
  const { t } = useTranslation();
  const [health_plan, setHealthPlan] = useState("")

  useEffect(() => {
    let url = window.location.pathname
    let sub = url.split("/")
    setHealthPlan(sub[2])
  }, [window.location.pathname]);

  /***
   * Funcion que se encargara para cambiar el lenguaje
   * donde verificara el useState para ver que lenguaje
   * esta y cambiarlo
   */
  const handleLanguage = () => {
    if (language === "en") {
      i18n.changeLanguage("es");
      setLanguage("es");
      setSelected(true);
    } else if (language === "es") {
      i18n.changeLanguage("en");
      setLanguage("en");
      setSelected(true);
    }
  };
  return (
    <Container>
      <span id="before-select"><FaLanguage size={20} /></span>
        <select name="language_select" id="language_select" onChange={handleLanguage} value={language}>
          <option value="en">{t("change_language_english")}</option>
          <option value="es">{t("change_language_spanish")}</option>
        </select>
    </Container>
  );
};
