/***
 * Add or delete Token in axios headers
 * */
import axiosClient from "./axios.js";

/***
 * update token in headers
 * @param {string} token Token to validate session.
 * */
const tokenAuth = (token) => {
  if (token) {
    axiosClient.defaults.headers.common["Authorization"] = `Token ${token}`;
    axiosClient.interceptors.request.use(function (config) {
      config.headers.Authorization = `Token ${token}`;
      return config;
    });
    axiosClient.defaults.xsrfCookieName = "csrftoken";
    axiosClient.defaults.xsrfHeaderName = "X-CSRFToken";
  } else {
    delete axiosClient.defaults.headers.common["Authorization"];
  }
};

export default tokenAuth;
