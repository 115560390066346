/***
 * Add BasePage for containt all project
 * This file is for any validation from backend without login
 * LayoutProvider containt BasePage
 * */
import React from "react";
import BasePage from "./components/layout/BasePage";
import LayoutProvider from "./context/layoutContext";
import { MenuProvider } from "./context/menuContext";
import AuthProvider from "./context/authContext";
import tokenAuth from "./config/tokenAuth";
import "react-toastify/dist/ReactToastify.css";

const token = localStorage.getItem("token");
if (token) {
  tokenAuth(token);
}

function App() {
  return (
    <AuthProvider>
      <MenuProvider>
        <LayoutProvider>
          <BasePage />
        </LayoutProvider>
      </MenuProvider>
    </AuthProvider>
  );
}

export default App;
