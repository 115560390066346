import React from "react";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {
    FaCalendarAlt,
    FaFlask,
    FaHandHoldingHeart
} from "react-icons/fa";

let origin = window.location.pathname.split("/")[1];

export const Content = styled.div`
  display: grid;
  grid-template-columns: repeat(${({origin}) => origin === "mediexcel" ? 3 : 2},
  minmax(100px, 300px));
  justify-content: center;
  grid-gap: 2rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, minmax(100px,300px));
    height: 70vh;
    overflow: scroll;
    width: 100%;
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, minmax(100px,300px));
    overflow: scroll;
  }
`;

const MenuItem = styled(Link)`
  height: 300px;
  border: 5px solid var(--excel-primary-color);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--excel-primary-color);
  text-decoration: none;

  svg {
    font-size: 3rem;
  }

  h1 {
    text-align: center;
  }
  
`;
const MenuItemMediExcel = styled(Link)`
  height: 300px;
  border: 5px solid var(--excel-primary-color);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--excel-primary-color);
  text-decoration: none;

  svg {
    font-size: 3rem;
  }

  h1 {
    text-align: center;
  }
  
  &:first-child{
    border-color: #005487;
    color: #005487;
  }

  &:hover {
    background-color: var(--excel-primary-color);
    color: white;
    cursor: pointer;
    
    &:first-child{
      background-color: #005487;
    }
  }
`;

const MyMediexcel = () => {
    const {t} = useTranslation();
    return (
        <Content origin={origin}>
            {origin === "mediexcel" && (
                <MenuItemMediExcel
                    origin={origin}
                    to={`/${origin}/my-health-plan/`}
                    data-testid="my-health-plan-link"
                >
                    <FaHandHoldingHeart/>
                    <h1>{t("my_mediexcel_plan")}</h1>
                </MenuItemMediExcel>
            )}

            <MenuItem origin={origin} to={`/${origin}/my-appointments/`}>
                <FaCalendarAlt/>
                <h1>{t("my_appointments")}</h1>
            </MenuItem>

            <MenuItem origin={origin} to={`/${origin}/my-labs/`}>
                <FaFlask/>
                <h1>{t("my_labs")}</h1>
            </MenuItem>
        </Content>
    );
};

export default MyMediexcel;
