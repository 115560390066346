import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Frame = styled.embed`
  height: 100%;
`;

const FindDoctor = () => {
  const { t } = useTranslation();
  return (
    <Frame
      data-testid="frame-container"
      src={t("find_doctor")}
      type="text/html"
    />
  );
};

export default FindDoctor;
