import React from "react";
import styled from "styled-components";
import MyMediexcel from "./MyMediexcel";

let origin = window.location.pathname.split("/")[1];

export const Content = styled.div``;

const Home = () => {
  return <MyMediexcel />;
};

export default Home;
