/***
 * Componente para enviar mensaje de validacion de correo
 */
import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import excel_logo from "assets/images/excel_logo.png";
import mediexcel_logo from "assets/images/mediexcel_logo.png";

let origin = window.location.pathname.split("/")[1];

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  img:nth-child(1) {
    width: ${({origin}) => origin === "mediexcel" ? 15 : 25}rem;
    margin: 1rem 0;
  }

  img:nth-child(2) {
    width: 25rem;
  }
`;

export const Card = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 30rem;
  height: 15rem;
  padding: 2rem;
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);

  p {
    font-weight: bold;
    font-size: 1.5rem;
  }

  button {
    background-color: var(${({ origin }) => `--${origin}-third-color`});
    border: none;
    color: ${({ origin }) => (origin === "mediexcel" ? "white" : "black")};
    padding: 0.5rem;
    min-width: 8rem;
    cursor: pointer;
    margin: 1rem 0;
  }
`;

const Message = ({ message, href }) => {
  const { t } = useTranslation();
  return (
    <Content>
        <Logos origin={origin}>
            <img
                src={origin === "mediexcel" ? mediexcel_logo : excel_logo}
                alt="Logo"
            />
            {origin === "mediexcel" && (<img src={excel_logo} alt="Logo"/>)}
        </Logos>
      <Card origin={origin}>
        <p>{t(message)}</p>
        {href === "login" ? (
          <a href={`/${origin}/login`}>
            <button>{t("postregister__go_to_login")}</button>
          </a>
        ) : (
          <a href={`/${origin}/`}>
            <button>{t("message__go_to_origin")}</button>
          </a>
        )}
      </Card>
    </Content>
  );
};

export default Message;
