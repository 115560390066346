/***
 * Component for password change
 */
import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import excel_logo from "assets/images/excel_logo.png";
import mediexcel_logo from "assets/images/mediexcel_logo.png";
import { layoutContext } from "context/layoutContext";
import Error from "components/common/Error";
import { FaAt } from "react-icons/fa";
import axiosClient from "config/axios";
import i18n from "i18next";
import { LanguageButton } from "components/common/LanguageButon";

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Logos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;

  img:nth-child(1) {
    width: ${({ origin }) => (origin === "mediexcel" ? 12 : 18)}rem;
    margin-top: 1rem !important;
  }

  img:nth-child(2) {
    /* margin-top: 1rem; */
    width: 18rem;
  }
`;

export const Form = styled.div`
  width: 25rem;
  display: flex;
  flex-direction: column;
  padding: 1rem 2rem;
  border-top: 0.5rem solid var(--excel-primary-color);
  background-color: var(--excel-third-color);

  h1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    color: black;
  }

  p {
    margin: 0.5rem;
    color: black;
  }

  form {
    display: flex;
    flex-direction: column;

    div {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      svg {
        position: absolute;
        margin: 0 -1rem;
        width: 2rem !important;
      }

      :nth-child(3) {
        justify-content: space-between;
      }
    }

    LanguageButton {
      display: flex-end;
    }

    button {
      :disabled {
        cursor: default;
        background-color: var(--excel-primary-color);
      }
      background-color: var(--excel-fourth-color);
      border: none;
      color: white;
      padding: 0.5rem;
      width: 8rem;
      cursor: pointer;
      margin: 0.5rem 0;
    }

    label {
      display: inline-block;
      margin: 0.5rem 0;
    }

    input {
      width: 100%;
      padding: 0.8rem 0.5rem;
      margin: 0.5rem 0;
      border: none;

      :focus {
        border: none;
        outline: none;

        border-bottom: 0.4rem solid var(--excel-primary-color);
        margin-bottom: 0.4rem;
      }
    }

    a {
      color: black;
    }
  }
`;

export const Links = styled.div`
  padding-top: 1rem;
  margin: auto;
`;

const LanguageContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Text = styled.p`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;

const ForgotPassword = () => {
  const layoutState = useContext(layoutContext);
  const { origin } = layoutState;

  const { t } = useTranslation();

  /**
   * Function to create component that shows form fields
   * @returns with the variable "forgot_password" of useState we return the values ​​entered
   */
  const [forgot_password, setForgotPassword] = useState({
    email_change: "",
    language: "",
    is_mediexcel: origin === "mediexcel",
  });

  /***
   * Error component is initialized as false
   */
  const [error, setError] = useState({
    status: false,
    message: null,
  });

  /***const Text = styled.p`
  display: flex;
  justify-content: center;
  margin-top: 1rem;
`;
   * Use user api to update password
   */

  const UpdatePassword = async (e) => {
    e.preventDefault();
    let data = {
      user: forgot_password.email_change,
      language: i18n.language,
    };
    await axiosClient
      .post("/auth/request_token_password/", data)
      .then((response) => {
        if (response.status === 202) {
          window.location.href = `/${origin}/update-password/`;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /***
   * Function that interacts with the input to access value values ​​every time it is updated
   * @param {object} e access the input values
   */
  const handleChange = (e) => {
    setForgotPassword({
      ...forgot_password,
      [e.target.name]: e.target.value,
    });
  };

  /***
   * Function that validates that the email was valid
   */
  useEffect(() => {
    validations();
  }, [forgot_password.email_change]);

  /***
   * Function that validates that the email field is not empty and if it is, send a message
   */
  const validations = () => {
    setError({
      status: false,
      message: null,
    });
    if (
      forgot_password.email_change &&
      !/\S+@\S+\.\S+/.test(forgot_password.email_change)
    ) {
      setError({
        status: true,
        message: t("auth__invalid_email"),
      });
      return;
    }
    if (!forgot_password.email_change) {
      setError({
        status: true,
        message: null,
      });
      return;
    }
  };

  const { email_change } = forgot_password;

  const { message, status } = error;

  return (
    <Content>
      <div origin={origin}>
        <Logos>
          <img
            src={origin === "mediexcel" ? mediexcel_logo : excel_logo}
            alt="Logo"
          />
        </Logos>
        <Text><h2><b>{origin === "mediexcel" ? `${t("powered__by_portal")}` : ""}</b></h2></Text>
        <Text>{origin === "mediexcel" ? `${t("powered__by")}` : ""}</Text>
        <Logos>
          {origin === "mediexcel" && <img src={excel_logo} alt="Logo" />}
        </Logos>
      </div>
      <Form origin={origin} onSubmit={UpdatePassword}>
        <LanguageContainer>
          <h1>{t("forgot_password__title")}</h1>
          <LanguageButton />
        </LanguageContainer>
        <p>{t("forgot_password__message")}</p>
        <form>
          <div>
            <FaAt />
            <input
              onChange={handleChange}
              type="email"
              name="email_change"
              className="u-full-width"
              placeholder={t("forgot_password__input")}
              value={email_change}
              data-testid="email_change"
              required
            />
          </div>
          <div>
            <button
              type="submit"
              className="u-full-width button-primary"
              disabled={status}
              data-testid="submit-button"
            >
              {t("forgot_password__button")}
            </button>
          </div>
        </form>
        {status && <Error message={message} />}
      </Form>
    </Content>
  );
};

export default ForgotPassword;
