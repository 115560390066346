import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import HttpApi from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { translationEN } from "./assets/locales/en/translation.js";
import { translationES } from "./assets/locales/es/translation.js";

const FALLBACKLNG = ["en"];
const AVAILABLELNG = ["en", "es"];

const resources = {
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
};

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    FALLBACKLNG,

    detection: {
      checkWhitelist: true,
    },

    debug: false,

    whitelist: AVAILABLELNG,

    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false, //   <---- this will do the magic
    },
  });

export default i18n;
