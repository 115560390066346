/***
 * User profile component
 */
import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { FaUserAlt, FaDatabase, FaAt, FaEdit } from "react-icons/fa";
import { FaOutdent } from "react-icons/fa";
import DataContent from "./DataContent";
import { MenuProfile } from "components/layout/MenuProfile";

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SideBar = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  min-width: 21rem;
  max-width: 21rem;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  margin-right: 2rem;
  border-radius: 5px;

  @media (max-width: 768px) {
    display: none;
  }
`;

const SideBarTitle = styled.div`
  height: 4rem;
  padding: 1rem;
  font-size: 1.5rem;
  font-weight: bold;
`;

const SideBarContent = styled.div`
  display: flex;
  color: black;
  align-items: center;
  padding: 1.5rem;
  list-style: none;
  height: 3.5rem;
  text-decoration: none;
`;

const SidebarLabel = styled.button`
  margin-left: 1rem;
  font-size: 1rem;
  background-color: transparent;
  border: none;

  &:hover {
    cursor: pointer;
    font-weight: bold;
  }
  &:disabled {
    cursor: not_allowed;
    color: gray;
  }
`;

const Content = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  border-radius: 5px;
`;

const ShowMenu = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: block;
  }
`;

export const UserProfile = () => {
  const [showMenuProfile, setShowMenuProfile] = useState(false);
  const [activeComponent, setActiveComponent] = useState("user-personal-data");
  const { t } = useTranslation();

  const handleChange = (e) => {
    setActiveComponent(e.target.name);
  };

  let menu_profile;

  if (showMenuProfile) {
    menu_profile = <MenuProfile showMenuProfile={showMenuProfile} setShowMenuProfile={setShowMenuProfile} activeComponent={activeComponent} setActiveComponent={setActiveComponent} />
  }

  return (
    <Container>
        <ShowMenu onClick={() => setShowMenuProfile(!showMenuProfile)}>
          <FaOutdent size={35} color='#005487' />
        </ShowMenu>
        <SideBar>
        <SideBarTitle>{t("profile")}</SideBarTitle>
        <SideBarContent>
          <FaUserAlt />
          <SidebarLabel name="user-personal-data" onClick={handleChange}>
            {t("personal_data")}
          </SidebarLabel>
        </SideBarContent>
        <SideBarContent>
          <FaDatabase />
          <SidebarLabel name="user-contact-data" onClick={handleChange}>
            {t("contact_data")}
          </SidebarLabel>
        </SideBarContent>
        <SideBarContent>
          <FaAt />
          <SidebarLabel name="user-change-mail-data" onClick={handleChange}>
            {t("change_email")}
          </SidebarLabel>
        </SideBarContent>
        <SideBarContent>
          <FaEdit />
          <SidebarLabel name="user-request-update-info" onClick={handleChange}>
            {t("change_personal_data")}
          </SidebarLabel>
        </SideBarContent>
      </SideBar>
        { menu_profile }
      <Content>
      <DataContent isActive={activeComponent} />
      </Content>
    </Container>
  );
};
