/***
 * Component that contain address
 */
import React from "react";
import styled from "styled-components";

let origin = window.location.pathname.split("/")[1];

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.p`
  font-size: 1.5rem;
  font-weight: bold;
  margin-top: 1rem;
`;

const List = styled.ul`
  list-style: none;
  padding: 0 20px;
  margin: 20px 0 20px 20px;
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-left: 3px solid var(--excel-primary-color);
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.16);

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-top: 1px solid #e0dddd;

  &:hover {
    font-weight: bold;
  }

  :first-of-type {
    border-top: none;
  }
`;

const Title = styled.span`
  font-size: 18px;
  color: #383737;
  margin-bottom: 5px;
`;

export const ContactComponent = ({ list, title }) => {
  console.log(title);
  switch (title) {
    case "Addresses":
    case "Direcciones":
      return (
        <Container>
          <Label>{title}</Label>
          <List origin={origin}>
            {list &&
              list.map((list) => (
                <ListItem key={list.id}>
                  <Title>
                    {list.address_line1} {list.address_line2}, {list.city_name},{" "}
                    {list.postal_code}
                  </Title>
                </ListItem>
              ))}
          </List>
        </Container>
      );
    case "Phones":
    case "Teléfonos":
      return (
        <Container>
          <Label>{title}</Label>
          <List origin={origin}>
            {list &&
              list.map((list) => (
                <ListItem key={list.id}>
                  <Title>{list.phone_number}</Title>
                </ListItem>
              ))}
          </List>
        </Container>
      );
    case "Emails":
    case "Correos":
      return (
        <Container>
          <Label>{title}</Label>
          <List origin={origin}>
            {list &&
              list.map((list) => (
                <ListItem key={list.id}>
                  <Title>{list.email}</Title>
                </ListItem>
              ))}
          </List>
        </Container>
      );
    default:
      return <></>;
  }
};
