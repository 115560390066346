import React, {useState, useEffect} from "react";
import styled from "styled-components";

const Tabs = styled.div`
  display: flex;
  justify-content: center;
  overflow: hidden;
  background: #fff;
  height: 3.75em;
  width: 100%;
`;

const Tab = styled.button`
  border: none;
  outline: none;
  cursor: pointer;
  width: 50%;
  position: relative;

  margin-right: 0.1em;
  border-bottom: ${(props) =>
          props.active ? "5px solid #000" : "3px solid #ccc"};
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  font-size: 1.3rem;
  background-color: white;
  height: ${(props) => (props.active ? "3em" : "2.6em; top:.4em")};
  transition: background-color 0.5s ease-in-out;
`;

const TabsComponent = ({handle, active, TabsName}) => {
    const [health_plan, setHealthPlan] = useState("")

    const getUrl = () => {
        let url = window.location.pathname
        let sub = url.split("/")
        setHealthPlan(sub[2])
    }

    useEffect(() => {
        getUrl()
    }, [window.location.pathname]);

    return (
        <Tabs>
            <Tab onClick={handle} active={active === 0} id={0}>
                {TabsName[0].name}
            </Tab>

            <Tab onClick={handle} active={active === 1} id={1}>
                {TabsName[1].name}
            </Tab>
        </Tabs>
    );
};

export default TabsComponent;
