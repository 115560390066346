import styled, { keyframes } from 'styled-components';

// Define keyframes for animation
export const fadeInLeft = keyframes`
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

// Define styled components
export const FadeInLeftDiv = styled.div`
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
  animation-fill-mode: both;
  -webkit-animation-fill-mode: both;
  -webkit-animation-name: ${fadeInLeft};
  animation-name: ${fadeInLeft};
`;

export const MenuFixedLab = styled.nav`
  display: grid;
  align-items: flex-start;
  place-items: flex-start;
  width: calc(100% - -32px);
  left: -16px;
  height: 100vh;
  background-color: var(--mediexcel-third-color-opacity);
  position: fixed;
  justify-content: center;
  justify-items: center;
  align-content: space-between;
  overflow: scroll;
`; 

export const MenuFixed = styled.nav`
  display: grid;
  align-items: flex-start;
  place-items: flex-start;
  width: calc(100% - -32px);
  left: -16px;
  top: 4rem;
  height: 100vh;
  background-color: var(--mediexcel-third-color-opacity);
  position: fixed;
  justify-content: center;
  justify-items: center;
  align-content: space-between;
  overflow: scroll;
`;

export const MenuList = styled.ol`
  list-style: none;
  text-align: center;
  margin-bottom: 2rem;
`;

export const MenuItem = styled.li`
  padding-top: 1rem;

  @media (max-width: 640px) {
    width: 20rem;
  }
`;

export const Link = styled.a`
  color: white;
  font-size: 2rem;

  &:hover {
    color: #fff;
    transition: 0.3s;
  }
`;

export const SideBarTitle = styled.div`
  height: 4rem;
  padding: 0rem 0.5rem 0 1rem;
  font-size: 1.5rem;
  font-weight: bold;
`;

export const SideBarContent = styled.button`
  display: flex;
  color: black;
  align-items: center;
  padding: 1.5rem;
  list-style: none;
  height: 3.5rem;
  text-decoration: none;
  margin: .2rem;
  background-color: transparent;
  background: #FAF9F6;
  width: 100%;
  border: none;

  &:hover {
    cursor: pointer;

    button {
      cursor: pointer;
      font-weight: bold;
    }
  }
`;

export const SidebarLabel = styled.button`
  color: #000;
  margin-left: 1rem;
  font-size: 1.2rem; 
  background-color: transparent;
  border: none;

  &:disabled {
    cursor: not_allowed;
    color: gray;
  }     
`;

export const SidebarLabelFD = styled.a`
  margin-left: 1rem;
  font-size: 1.2rem;
  background-color: transparent;
  border: none;
  text-decoration: none;
  color: black;

  &:disabled {
    cursor: not_allowed;
    color: gray;
  }
`;

export const SideBarContentFD = styled.a`
  display: flex;
  color: black;
  align-items: center;
  padding: 1.5rem;
  list-style: none;
  height: 3.5rem;
  text-decoration: none;
  margin: .2rem;
  background-color: transparent;
  border: none;
  background: #FAF9F6;

&:hover {
  cursor: pointer;

  a {
    cursor: pointer;
    font-weight: bold;
  }
}
`;

export const MenuScroll = styled.span`
  height: 65vh;
  width: 100%;
  overflow: scroll;
`;