import React from "react";
import {useTranslation} from "react-i18next";
import { FaUserAlt, FaDatabase, FaAt, FaEdit } from "react-icons/fa";
import { 
  FadeInLeftDiv, 
  MenuFixedLab, 
  MenuList, 
  MenuItem,
  SideBarTitle,
  SideBarContent,
  SidebarLabel
} from "./styles/Menu";

export function MenuProfile({ 
  showMenuProfile,
  setShowMenuProfile,
  setActiveComponent
 }) {
  const {t} = useTranslation();

  const handleChange = (e) => {
    setActiveComponent(e.target.name);
  };

  return (
    <FadeInLeftDiv>
      <MenuFixedLab>
        <MenuList>
          <MenuItem>
            <SideBarTitle data-testid="profile-header">
            {t("profile")}
            </SideBarTitle>
          </MenuItem>
          <MenuItem onClick={() => setShowMenuProfile(!showMenuProfile)}>
            <SideBarContent>
              <FaUserAlt />
              <SidebarLabel name="user-personal-data" onClick={handleChange}>
                {t("personal_data")}
              </SidebarLabel>
            </SideBarContent>
          </MenuItem>
          <MenuItem onClick={() => setShowMenuProfile(!showMenuProfile)}>
            <SideBarContent>
              <FaDatabase />
              <SidebarLabel name="user-contact-data" onClick={handleChange}>
                {t("contact_data")}
              </SidebarLabel>
            </SideBarContent>
          </MenuItem>
          <MenuItem onClick={() => setShowMenuProfile(!showMenuProfile)}>
            <SideBarContent>
              <FaAt />
              <SidebarLabel name="user-change-mail-data" onClick={handleChange}>
                {t("change_email")}
              </SidebarLabel>
            </SideBarContent>
          </MenuItem>
          <MenuItem onClick={() => setShowMenuProfile(!showMenuProfile)}>
            <SideBarContent>
              <FaEdit />
              <SidebarLabel name="user-request-update-info" onClick={handleChange}>
                {t("change_personal_data")}
              </SidebarLabel>
            </SideBarContent>
          </MenuItem>
        </MenuList>
      </MenuFixedLab> 
    </FadeInLeftDiv>
  )
}
