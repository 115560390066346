import React from 'react'
import {useTranslation} from "react-i18next";
import { FaTasks, FaStickyNote } from "react-icons/fa";
import { 
    FadeInLeftDiv, 
    MenuFixedLab, 
    MenuList, 
    MenuItem,
    Link,
    SideBarTitle,
    SideBarContent,
    SidebarLabel,
    SidebarLabelFD,
    SideBarContentFD
} from "./styles/Menu";


export function MenuLab({ setActiveComponent, setShowMenu, showMenu }) {
  const {t} = useTranslation();

  const handleChange = (e) => {
    setActiveComponent(e.target.name);
  };

  return (
    <>
      <FadeInLeftDiv>
        <MenuFixedLab>
          <MenuList>
            <MenuItem>
              <SideBarTitle data-testid="profile-header">
              {t("my_labs")}
              </SideBarTitle>
            </MenuItem>
            <MenuItem onClick={() => setShowMenu(!showMenu)}>
              <SideBarContent>
                <FaTasks />
                <SidebarLabel name="my_labs__result" onClick={handleChange}>
                {t("my_labs__result")}
                </SidebarLabel>
              </SideBarContent>
            </MenuItem>
            <MenuItem onClick={() => setShowMenu(!showMenu)}>
              <SideBarContent>
                <FaStickyNote />
                <SidebarLabel name="my_labs__request" onClick={handleChange}>
                  {t("my_labs__request")}
                </SidebarLabel>
              </SideBarContent>
            </MenuItem>
          </MenuList>
        </MenuFixedLab>
      </FadeInLeftDiv>
    </>
  )
}
