import React, { useState } from "react";
import styled from "styled-components";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Copays from "./Copays";

let plan_names = require('assets/plan_names.json');
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Title = styled.h1`
  text-align: center;
  color: #005487;
  font-weight: normal;

  &:hover {
    cursor: pointer;
  }
`;

const SubTitle = styled.h3`
  color: #000;
  font-weight: normal;
`;

const Text = styled.p`
  width: 50%;
  font-size: 1rem;
  margin-bottom: 5px;
  word-wrap: break-word;
  margin-top: 30px;
  text-align: center;
  color: #b0b3b8;
`;

const ButtonContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  padding-bottom: 2rem;
`;

const Button = styled.a`
  background-color: #005487;
  border: none;
  padding: 1rem;
  color: white;
  text-decoration: none;

  &:hover {
    background-color: #00436c;
    cursor: pointer;
  }
`;

const CollapseContent = styled.div`
  display: ${(props) => (props.collapse ? "none" : "block")};
`;

const Plan = ({ copays, plan, type }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { t } = useTranslation();

  const handleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <>
      <Title onClick={handleCollapsed}>
        {i18n.language === "en" ? (<>
          {type} - {plan.year} {plan_names[plan.name]} Plan
        <span>{isCollapsed ? <FaCaretDown /> : <FaCaretUp />}</span>
        </>):(<>
          {type} - Plan {plan_names[plan.name]} {plan.year} 
        <span>{isCollapsed ? <FaCaretDown /> : <FaCaretUp />}</span>
        </>)}
      </Title>
      <CollapseContent collapse={isCollapsed}>
        {copays ? (
          <Container>
            <SubTitle>{t("my_plans__copays")}</SubTitle>
            <Copays source={copays} />
            <Text>{t("my_plans__description_copay")}</Text>
            <ButtonContainer>
              <Button
                href={
                  i18n.language === "en"
                    ? plan.document_url_en
                    : plan.document_url_es
                }
                target="_blank"
              >
                {t("my_plans__document_button")}
              </Button>
            </ButtonContainer>
          </Container>
        ) : (
          <Container>
            <Text>{t("my_plans__description_no_copay")}</Text>
            <ButtonContainer>
              {type === "Dental Plan" ? (
                <Button
                  href={
                    i18n.language === "en"
                      ? plan.document_url_en
                      : plan.document_url_es
                  }
                  target="_blank"
                >
                  {t("my_plans__document_button")}
                </Button>
              ) : (
                <Button
                  href={
                    i18n.language === "en"
                      ? plan.document_url_en
                      : plan.document_url_es
                  }
                  target="_blank"
                >
                  {t("my_plans__document_button")}
                </Button>
              )}
            </ButtonContainer>
          </Container>
        )}
      </CollapseContent>
    </>
  );
};

export default Plan;
