import React from "react";
import styled from "styled-components";
import MediexcelLogo from "assets/images/mediexcel_logo.png";
import PdfFooter from "assets/images/pdf_footer_regular.png";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-family: "Roboto", Arial, sans-serif;
  font-size: 9pt;
`;

const Header = styled.div`
  display: table;
  width: 100%;
  table-layout: fixed;
  color: #055591;
  margin-bottom: 4pt;
`;

const ColumnLeft = styled.div`
  display: table-cell;
  padding-left: 16pt;
`;

const ColumnRight = styled.div`
  display: table-cell;
  padding-right: 16pt;
  vertical-align: top;
`;

const ColumnTitle = styled.div`
  display: table-cell;
  text-align: center;
  vertical-align: middle;

  h1 {
    font-size: 24pt;
  }
`;

const Logo = styled.img`
  width: 5cm;
  margin-bottom: 8pt;
`;

const Company = styled.ul`
  text-align: left;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    font-size: 8pt;
  }
`;

const Name = styled.li`
  font-size: 10pt !important;
  font-weight: bold !important;
`;

const PresPhy = styled.ul`
  font-size: 8pt;
  text-align: right;
  list-style-type: none;
  margin: 0 0 8pt 0;
  padding: 0;
`;

const PatientTable = styled.table`
  border-collapse: collapse;
  width: 100%;

  thead {
    background-color: #055591;
    color: #fff;
    font-size: 8pt;
    text-align: left;

    tr th {
      font-size: 8pt;
      padding: 4pt 0 4pt 0;
      border-bottom: solid #055591;
    }

    th:first-child,
    th:last-child {
      width: 16pt;
    }

    th:last-child {
      border-right: none;
    }
  }

  tbody tr td {
    padding: 4pt 0 4pt 0;
  }
`;

const ListTrue = styled.div`
  display: table;
  table-layout: fixed;
  width: 100%;
  float: left;

  table {
    width: 100%;
    border: 0;

    thead {
      background-color: #055591;

      th {
        color: white;
        padding: 3pt;
      }

      th:last-child {
        width: 250px;
      }
    }

    tbody {
      td:first-child {
        font-weight: bolder;
      }
    }
  }
`;

const Indications = styled.table`
  width: 100%;
  margin-top: 30px;

  tr:nth-child(1),
  tr:nth-child(4),
  tr:nth-child(6) {
    td {
      font-weight: bolder;
    }
  }

  tr:nth-child(2) {
    td {
      background: #eaeaea;
      padding: 5px;
      font-size: 9px;
    }
  }
`;

const Signature = styled.div`
  display: table;
  width: 100%;
  table-layout: fixed;
  margin-top: 20px;
  margin-bottom: 1pt;
`;

const ColumnNote = styled.div`
  display: table-cell;
  font-size: 8pt;
  padding-left: 16pt;
  padding-right: 16pt;
  text-align: justify;
  width: 14cm;

  p {
    margin-top: 0;

    span {
      font-weight: bolder;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 12pt;
  }
`;

const ColumnSignature = styled.div`
  display: table-cell;
  font-size: 8pt;
  padding-left: 10pt;
  padding-right: 16pt;
  text-align: center;
  vertical-align: bottom;

  p {
    margin-top: 0;

    span {
      font-weight: bolder;
    }
  }

  ul {
    list-style-type: disc;
    padding-left: 12pt;
  }
`;

const SignatureName = styled.div`
  margin-bottom: 1pt;

  img {
    max-width: 300px;
    max-height: 70px;
  }
`;

const SignatureLabel = styled.div`
  border-top: thin solid #000;
  display: table;
  height: 100%;
  width: 100%;
`;

const Footer = styled.div`
  display: table;
  width: 100%;
  table-layout: fixed;
`;

const ColumnFooter = styled.div`
  display: table-cell;
  background-image: url(${PdfFooter});
  background-position: center;
  background-repeat: no-repeat;
  background-size: auto 4.9cm;
  height: 5cm;
  vertical-align: top;
`;


const LabRequestPdf = () => {
  const  request  = JSON.parse(localStorage.getItem('request'));

  return (
    <Container>
      <Header>
        <ColumnLeft>
          <Logo src={MediexcelLogo} />
          <Company>
            <Name>MEDIEXCEL, S.A. DE C.V.</Name>
            <li>{request.contact.address_line1}</li>
            <li>{request.contact.address_line2}</li>
            <li>Tel. {request.contact.mx_phone}</li>
          </Company>
        </ColumnLeft>
        <ColumnTitle>
          <h1>SOLICITUD DE LABORATORIO</h1>
        </ColumnTitle>
        <ColumnRight>
          <PresPhy>
            <li>
              NOMENCLATURA UM-EXP-CLIN-007-
              {request.date_request.substring(7, 11)}
            </li>
            <li>
              FOLIO: <strong>{request.id}</strong>
            </li>
            <li>FECHA: {request.date_request}</li>
            <li>HORA: {request.time}</li>
          </PresPhy>
          <PresPhy>
            <li>DR(A). {request.physician.full_name}</li>
            {request.physician.specialties &&
              request.physician.specialties.map((specialty) => (
                <>
                  <li>
                    Especialidad:{" "}
                    <strong>{request.physician.specialties[0].name}</strong>
                  </li>
                  {specialty.university !== "NA" &&
                    specialty.university !== "na" && (
                      <li>Universidad: {specialty.university}</li>
                    )}
                  {specialty.license !== "0" && specialty.license !== "na" && (
                    <li>Cedula: {specialty.license}</li>
                  )}
                </>
              ))}
          </PresPhy>
        </ColumnRight>
      </Header>
      <PatientTable>
        <thead>
          <tr>
            <th></th>
            <th>IDENTIFICADOR</th>
            <th>NOMBRE DE PACIENTE</th>
            <th>FECHA DE NACIMIENTO</th>
            <th>EDAD</th>
            <th>TIPO DE PLAN</th>
            <th>COPAGO</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td>{request.patient.credential}</td>
            <td>{request.patient.full_name}</td>
            <td>{request.patient.date_of_birth}</td>
            <td>{request.patient.age}</td>
            <td>{request.patient.plan}</td>
            <td>{request.copay}</td>
            <td></td>
          </tr>
        </tbody>
      </PatientTable>
      <ListTrue>
        <table>
          <thead>
            <tr>
              <th> ESTUDIOS SOLICITADOS</th>
              <th>CATEGORIA</th>
            </tr>
          </thead>
          <tbody>
            {request.list_true &&
              request.list_true.map((detail) => (
                <tr>
                  <td>- {detail.laboratorio_estudios.description}</td>
                  <td>{detail.laboratorio_estudios.clasification}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </ListTrue>
      <Indications>
        <tr>
          <td>Indicaciones</td>
        </tr>
        <tr>
          <td>
            {request.list_true &&
              request.list_true.map((detail) => (
                <span>
                  <b>{detail.laboratorio_estudios.description}:</b>
                  {detail.laboratorio_estudios.indications} <br />
                </span>
              ))}
          </td>
        </tr>
        <tr>
          <td colSpan="2"></td>
          <td colSpan="2"></td>
        </tr>
        <tr>
          <td>Observaciones</td>
        </tr>
        <tr>
          <td>{request.observations}</td>
        </tr>
        <tr>
          <td>Especificaciones</td>
        </tr>
        <tr>
          <td>{request.specifications}</td>
        </tr>
      </Indications>
      <Signature>
        <ColumnNote>
          <p>
            <span>IMPORTANTE:</span> Esta Solicitud Tiene Vigencia de{" "}
            <span>{request.request_duration}</span> Dias a Partir de la Fecha de
            Expedicion.
          </p>
          <ul>
            <li>
              En caso de extravío, podrá solicitar la reimpresión con costo por
              servicio de Administración.
            </li>
            <li>No requiere cita</li>
            <li>
              <i>
                Si tiene preguntas acerca de los estudios solicitados, puede
                llamar a la Línea Médica (Dr. Line){" "}
                <strong>664 633-85-55</strong> y <strong>619-365-43-46</strong>
              </i>
            </li>
          </ul>
        </ColumnNote>
        <ColumnSignature>
          <SignatureName>
            <img src={request.physician.signature_url} alt="" />
            <p>DR(A). {request.physician.full_name}</p>
          </SignatureName>
          <SignatureLabel>
            <p>Firma del médico</p>
          </SignatureLabel>
        </ColumnSignature>
      </Signature>
      <Footer>
        <ColumnFooter />
      </Footer>
    </Container>
  );
};

export default LabRequestPdf;
