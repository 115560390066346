import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import TabsComponent from "components/common/TabsComponent";
import { getAppointments } from "data_services/Appointments/Appointments";
import Appointment from "./Appointment";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const NoAppointments = styled.h1`
  margin-left: auto;
  margin-right: auto;
  padding: 4rem;
  text-align: center !important;
  color: #c3c3c3;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Loading = styled(AiOutlineLoading3Quarters)`
  color: #005487;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const Appointments = () => {
  const [active, setActive] = useState(0);
  const [all_appointments, setAllAppointments] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = (e) => {
    setActive(parseInt(e.target.id, 0));
  };

  useEffect(() => {
    fetchAppointments();
  }, []);

  // useEffect(() => {
  //     filterAppointments();
  // }, [active, all_appointments]);

  /***
   * filter appointments
   */
  // const filterAppointments = () => {
  //     const tmp_appointments = [];
  //     all_appointments.forEach((item) => {
  //         console.log(item)
  //         if (active === 0) {
  //             if (item.consultation_subtype !== 5 && item.consultation_subtype !== 9) {
  //                 tmp_appointments.push(item);
  //             }
  //         } else {
  //             if (item.consultation_subtype === 5 || item.consultation_subtype === 9) {
  //                 tmp_appointments.push(item);
  //             }
  //         }
  //     });
  //
  //     setAppointments(tmp_appointments);
  // };

  const fetchAppointments = async () => {
    let result = await getAppointments();
    setAllAppointments(result.result);
    setIsLoading(false);
  };

  const Tabs = [
    { name: `${t("in_person")}` },
    { name: `${t("virtual_care")}` },
  ];
  return (
    <Container>
      {/*<TabsComponent handle={handleClick} active={active} TabsName={Tabs}/>*/}
      <h1>{t("upcoming_apps")}</h1>
      <Content>
        {!isLoading ? (
          <>
            {all_appointments.length > 0 ? (
                <Appointment appointments={all_appointments} />
            ) : (
                <NoAppointments>{t("no_apps")}</NoAppointments>
            )}
          </>
        ) : (
          <>
            <Loading size={100} />
            <h1>{t("loading")}</h1>
          </>
        )}
      </Content>
      {/*<Content active={active === 1}>*/}
      {/*    <Appointment appointments={appointments}/>*/}
      {/*</Content>*/}
    </Container>
  );
};

export default Appointments;
