/***
 * base layout with navbar, content and footer
 * read pathname from url and extract fist part
 * in useffect verify origin, must be mediexcel or excel
 * default value is excel
 * set origin in context to read styles
 */
import React, { useState, useEffect, useContext } from "react";
import { useLocation } from "react-router-dom";
import { layoutContext } from "context/layoutContext";
import { authContext } from "context/authContext";
import { b64_to_utf8 } from "utils/encodes";
import Content from "./Content";
import Footer from "./Footer";
import Navbar from "./Navbar";
import axiosClient from "config/axios";
import NotFound from "components/layout/NotFound";
import { MenuContext } from "context/menuContext";

const token_ls = localStorage.getItem("token");
let path_origin = window.location.pathname.split("/")[1];
let global_origin = localStorage.getItem("origin");
if (global_origin) {
  global_origin = b64_to_utf8(global_origin);
}
/**
 * get location to dont activate redirect in login and signup
 * load context to update origin
 */
const BasePage = () => {
  const location = useLocation();
  const layoutState = useContext(layoutContext);
  const authState = useContext(authContext);
  const { menu } = useContext(MenuContext);
  const [signoutTime, setSignoutTime] = useState(1800000);
  const [show_navbar, setShowNavbar] = useState(false);
  const { setOrigin } = layoutState;
  const { setToken, setUserData, setLogout } = authState;
  let logoutTimeout;

  /***
   *  Redirect to login
   *  Pending: Clear Session
   */
  const logout = () => {
    setLogout();
    setSignoutTime(1800000);
  };

  /**
   * set time to timeout
   */
  const setTimeouts = () => {
    logoutTimeout = setTimeout(logout, signoutTime);
  };

  /**
   * Clear time to logout
   */
  const clearTimeouts = () => {
    if (logoutTimeout) clearTimeout(logoutTimeout);
  };

  /**
   * Check events in any screen except login and signup
   * load
   * mousemove
   * mousedown
   * click
   * scroll
   * keypress
   */
  const checkTime = () => {
    if (
      location.pathname !== `/${path_origin}/login` &&
      location.pathname !== `/${path_origin}/signup`
    ) {
      const events = [
        "load",
        "mousemove",
        "mousedown",
        "click",
        "scroll",
        "keypress",
      ];

      const resetTimeout = () => {
        clearTimeouts();
        setTimeouts();
      };

      /**
       * add all events in list to dom
       */
      for (let i in events) {
        window.addEventListener(events[i], resetTimeout);
      }

      setTimeouts();
      return () => {
        for (let i in events) {
          window.removeEventListener(events[i], resetTimeout);
          clearTimeouts();
        }
      };
    }
  };
  
  const noNavbar = () => {
    if(location.pathname === `/${path_origin}/my-labs/pdf/`){
      setShowNavbar(false);
    }
  }


  /***
   * Get user data
   */
  const getUserData = () => {
    const no_navbar = [
      `/${path_origin}/login`,
      `/${path_origin}/login/`,
      `/${path_origin}/signup`,
      `/${path_origin}/signup/`,
      `/${path_origin}/post-register`,
      `/${path_origin}/post-register/`,
      `/${path_origin}/forgot-password`,
      `/${path_origin}/forgot-password/`,
      `/${path_origin}/update-password/`,
      `/${path_origin}/update-password`,
      `/${path_origin}/change-password/`,
      `/${path_origin}/change-password`,

    ];
    const show = no_navbar.indexOf(location.pathname) === -1;
    setShowNavbar(show);

    const token = localStorage.getItem("token");
    if (token && !show) {
      window.location.href = `/${path_origin}/`;
    } else if (!token && show) {
      window.location.href = `/${path_origin}/login`;
    } else {
      if (show) {
        axiosClient
          .get("/auth/userdata/")
          .then((response) => {
            setUserData(response.data);
          })
          .catch((error) => {
            setLogout();
          });

        let data = localStorage.getItem("data");
        if (data) {
          data = JSON.parse(data);
          setToken(data);
        }
      }
    }
  };

  /***
   * Check Origin
   */

  const checkOrigin = () => {
    if (path_origin !== "mediexcel" && path_origin !== "excel") {
      path_origin = "mediexcel";
    }

    setOrigin(path_origin);
  };

  /***
   * set origin in context
   */
  useEffect(() => {
    checkOrigin();
    checkTime();
    getUserData();
    noNavbar();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!token_ls && show_navbar) return null;

  return (
    <div className="basepage">
      {global_origin && path_origin !== global_origin ? (
        <NotFound origin={global_origin} />
      ) : (
        <>
          {show_navbar && <Navbar />}
          { menu }
          <Content no_background={show_navbar} />
          <Footer no_background={show_navbar} />
        </>
      )}
    </div>
  );
};

export default BasePage;
