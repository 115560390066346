import React from "react";
import i18n from "i18next";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";

const Cell = styled(TableCell)`
  background-color: #262526;
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
  width: 25%;
`;

const Row = styled(TableRow)`
  td {
    text-align: center !important;
  }

  &:hover {
    background-color: #d8d9da;

    td {
      font-weight: bold !important;
    }
  }
`;

const Appointment = ({ appointments }) => {
  const { t } = useTranslation();

  return (
    <>
      <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <Cell>{t("my_labs__requests_type")}</Cell>
                  <Cell>{t("my_labs__requests_date")}</Cell>
                  <Cell>{t("time_label_table")}</Cell>
                  <Cell>{t("specialty_label_table")}</Cell>
                </TableRow>
              </TableHead>
              <TableBody>
                {appointments.length &&
                  appointments.map((appointment) => (
                    <Row key={appointments.id}>
                      <TableCell>
                        {i18n.language === "en"
                          ? appointment.consultation_type
                          : appointment.consultation_type_es}
                      </TableCell>
                      <TableCell>
                        {i18n.language === "en"
                          ? appointment.appointment_date_eng
                          : appointment.appointment_date}
                      </TableCell>
                      <TableCell>{appointment.appointment_time}</TableCell>
                      <TableCell>
                        {i18n.language === "en"
                          ? appointment.appointment_specialty_eng
                          : appointment.appointment_specialty}
                      </TableCell>
                    </Row>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
    </>
  );
};

export default Appointment;
