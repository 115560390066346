/***
 * Create file with axios configuration
 * */

import axios from "axios";

let base_url = "//backend.saludexcel.com";
switch (document.location.host) {
  case "saludexcel.com":
    base_url = "//backend.saludexcel.com";
    break;
  case "sandbox.saludexcel.com":
    base_url = "//backendsb.saludexcel.com";
    break;
  case "127.0.0.1:3001":
    base_url = "//127.0.0.1:8001";
    break;
  default:
    base_url = "//backend.saludexcel.com";
    break;
}

const axiosClient = axios.create({
  baseURL: base_url,
});

export default axiosClient;
