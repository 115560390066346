import React, { useEffect, useState } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { getLabsResult } from "../../../data_services/Labs/LabsResult";
import TableContainer from "@material-ui/core/TableContainer";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FaPrint } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  align-items: center;
`;

const PdfButton = styled.a`
  border: none;
  background-color: transparent;

  &:hover {
    cursor: pointer;
  }

  svg {
    font-size: 1.5rem;
    color: black;
  }
`;
const Cell = styled(TableCell)`
  background-color: #262526;
  color: white !important;
  font-weight: bold !important;
  text-align: center !important;
  width: 25%;
`;

const Row = styled(TableRow)`
  td {
    text-align: center !important;
  }

  &:hover {
    background-color: #d8d9da;
    td {
      font-weight: bold !important;
    }
  }
`;

const Title = styled.h1``;

const Loading = styled(AiOutlineLoading3Quarters)`
  color: #005487;
  -webkit-animation: spin 4s linear infinite;
  -moz-animation: spin 4s linear infinite;
  animation: spin 4s linear infinite;
  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const LabsResult = () => {
  const [lab_result, setLabResult] = useState([]);
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);

  const labresult = async () => {
    let result = await getLabsResult();
    setLabResult(result.result);
    setIsLoading(false);
  };

  useEffect(() => {
    labresult();
  }, []);

  return (
    <Container>
      <Title>{t("my_labs__result")}</Title>
      {!isLoading ? (
        <>
          {lab_result.length > 0 ? (
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <Cell>{t("my_labs__requests_type")}</Cell>
                    <Cell>{t("my_labs__requests_date")}</Cell>
                    <Cell>{t("my_labs__requests_studies")}</Cell>
                    <Cell>{t("my_labs__requests_detail")}</Cell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {lab_result &&
                    lab_result.map((lab_result) => (
                      <Row key={lab_result.id}>
                        <TableCell>{t("my_labs__results")}</TableCell>
                        <TableCell>{lab_result.date_upload}</TableCell>
                        <TableCell>{lab_result.number_of_results}</TableCell>
                        <TableCell>
                          <PdfButton
                            href={lab_result.file.replace(
                              "backend.saludexcel.com",
                              "mediexcel-production.s3.amazonaws.com"
                            )}
                            target="_blank"
                          >
                            <FaPrint />
                          </PdfButton>
                        </TableCell>
                      </Row>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          ) : (
            <Title>{t("my_lab__result_not_found")}</Title>
          )}
        </>
      ) : (
        <>
          <Loading size={100} />
          <Title>{t("loading")}</Title>
        </>
      )}
    </Container>
  );
};

export default LabsResult;
